<template>
  <div  class="flex flex-col rounded-lg shadow-lg overflow-hidden" >
        <div class="flex-shrink-0">
          <img class="h-48 w-full bg-slate-800 p-5" :src="require('@/assets/icons/' + imageName + '')" alt="" />
        </div>
        <div :class="titleBlockStyle">
          <div :class="flex-1">
              <p class="text-xl font-semibold text-gray-50">
                {{ title }}
              </p>
              <p class="mt-3 text-base text-gray-200">
                {{ preview }}
              </p>
          </div>
        </div>
      </div>
  </template>

  <script >

  export default {
    props: ['title', 'titleBlockStyle', 'preview', 'imageName']
  };
  </script>
