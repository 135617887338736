/* eslint-disable */
import { createWebHistory, createRouter } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'
import Home from './components/Home.vue'
import VExpoLab from './components/VExpoLab.vue'
import Incenti5 from './components/Incenti5.vue'
import Bika from './components/Bika.vue'
import BoomRuleAccess from './components/BoomRuleAccess.vue'
import Tokaly from './components/Tokaly2.vue'
import Tokaly2 from './components/Tokaly.vue'
import AboutUs from './components/AboutUs.vue'
import ContactUs from './components/ContactUs.vue'
import Products from './components/Products.vue'
import Walkabout from './components/Walkabout.vue'
import CookiePolicy from './components/CookiePolicy.vue'
import PrivacyPolicy from './components/PrivacyPolicy.vue'
import PAIA from './components/PAIA.vue'
import FAQ from './components/FAQ.vue'
import PageNotFound from './components/PageNotFound.vue'

// Vue.use(VueRouter);

const routes = [

  {
    name: 'Home',
    path: '/',
    component: Home
  },
  {
    name: 'VExpoLab',
    path: '/VExpoLab',
    component: VExpoLab
  },
  {
    name: 'Incenti5',
    path: '/Incenti5',
    component: Incenti5
  },
  {
    name: 'Bika',
    path: '/Bika',
    component: Bika
  },
  {
    name: 'BoomRuleAccess',
    path: '/BoomRuleAccess',
    component: BoomRuleAccess
  },
  {
    name: 'Tokaly',
    path: '/Tokaly',
    component: Tokaly
  },
  {
    name: 'Tokaly2',
    path: '/Tokaly2',
    component: Tokaly2
  },
  {
    name: 'Walkabout',
    path: '/Walkabout',
    component: Walkabout
  },
  {
    name: 'Products',
    path: '/products',
    component: Products
  },
  {
    name: 'AboutUs',
    path: '/about-us',
    component: AboutUs
  },
  {
    name: 'ContactUs',
    path: '/contact-us',
    component: ContactUs
  },
  {
    name: 'CookiePolicy',
    path: '/CookiePolicy',
    component: CookiePolicy
  },
  {
    name: 'PrivacyPolicy',
    path: '/PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    name: 'PAIA',
    path: '/PAIA',
    component: PAIA
  },
  {
    name: 'FAQ',
    path: '/faq',
    component: FAQ
  },
  {
    name: 'PageNotFound',
    path: '/:pathMatch(.*)*',
    component: PageNotFound
  }
]

const router = new createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes,
  scrollBehavior () {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  }
})

trackRouter(router)

export default router
