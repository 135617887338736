<template>

<div class="flex flex-col rounded-lg shadow-lg overflow-hidden bg-gray-100 border-2 border-gray-600">
    <div class="flex-shrink-0 bg-white">
      <img class="sm:h-72 lg:h-40 xl:h-52 w-full object-cover" :src="require('@/assets/images/games/' + imageName + '')" :alt="alt" />
    </div>
</div>
</template>

<script >
  export default {
    props: ['imageName', 'alt']
  };
</script>