/* eslint-disable */
import { defineComponent, h } from 'vue'
import {
  PhoneIcon,
  MailIcon,
  LocationMarkerIcon
} from '@heroicons/vue/outline'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

// https://vue-hero-icons.netlify.app

const navigation = [
  { name: 'Products', href: '/#productsBlock' },
  { name: 'Incenti5', href: '/incenti5' },
  { name: 'WalkAbout', href: '/Walkabout' },
  { name: 'Bika', href: '/Bika' },
  { name: 'Tokaly', href: '/Tokaly' },
  { name: 'VExpoLab', href: '/VExpoLab' }
]

const Components = [
  {
    tweenId: 'gsapComponentsTween01', // for flipping images.
    title: 'Features',
    category: { name: '', href: '#' },
    imageUrl: require('../assets/images/incenti5/congrats.png'),
    preview: 'Incenti-5 offers vouchers, electronic and manual draws and bonus features for points earned or spent, special events such as birthdays, anniversaries, and visits.'
  },
  {
    title: 'Why use Incenti-5?',
    category: { name: '', href: '#' },
    imageUrl: require('../assets/images/incenti5/why.png'),
    preview: 'Reward, incentivise and engage Customers. Reduce points liablility and increase visits.'
  },
  {
    title: 'Offerings',
    category: { name: '', href: '#' },
    imageUrl: require('../assets/images/photo-1488590528505-98d2b5aba04b.jpg'),
    preview: 'Three draw types - Electronic, Golden Number and Manual Draws. Vouchers and voucher redemption, Incentive games and Non-gaming rewards.'
  },
  {
    title: 'Selection Criteria',
    category: { name: '', href: '#/vexpolabs' },
    imageUrl: require('../assets/images/incenti5/segmentation.png'),
    preview: 'Tier, Group​, Birthday (by date/week/month), Anniversary (by date/week/month), Special days (e.g. Valentines day or Casino Birthday)​, Age between​, Gender, Points earned (for period)​, Points purchase (not available for all system providers)​'
  },
  {
    title: 'Casino System Integration',
    category: { name: '', href: '#' },
    imageUrl: require('../assets/images/incenti5/casino.png'),
    preview: 'Incenti-5 is a tool designed for the gaming industry as a customer engagement and promotions tool. Integrated with: Bally, ACE, DRGT and more by request.'
  },
  {
    title: 'Covid-19 Compliant',
    category: { name: '', href: '#' },
    imageUrl: require('../assets/images/incenti5/covid.png'),
    preview: 'Incenti-5 is fully Covid-aware through touch-free printing and mobile phone kiosks. Alternatively Customers can access promotions through free-standing kiosks.'
  }
]

const icons = [
  // {
  //  name: 'Chat',
  //  description: 'Communicate via chat with anyone in your organization or team.',
  //  imageUrl: require('../assets/bika/Chat.png'),
  // },
  {
    name: 'Broadcast',
    description: 'Communicate to your organisation with SMS, Email and more.',
    imageUrl: require('../assets/bika/Broadcast.png')
  },
  {
    name: 'Bulletins',
    description: 'Digitise your bulletin boards.',
    imageUrl: require('../assets/bika/Bulletins.png')
  },
  {
    name: 'Covid Screening',
    description: 'Manage employee screenings digitally with flexible integration points.',
    imageUrl: require('../assets/bika/CovidScreening.png')
  },
  {
    name: 'KPIs',
    description: 'Quantify objectives with flexible KPI\'s and moderation.',
    imageUrl: require('../assets/bika/KPIs.png')
  },
  {
    name: 'Inbox',
    description: 'Send targeted messages to employees, visitors, and contractors.',
    imageUrl: require('../assets/bika/Messages.png')
  },
  {
    name: 'Induction',
    description: 'Induct employees and visitors by making use of video and rich text content. Inductions include flexible questionnaires and pass rates. Connect this with visitor management.',
    imageUrl: require('../assets/bika/Induction.png')
  },
  {
    name: 'Inspections',
    description: 'Create checklists on assets, maintenance or other to be completed by specific areas of your organisation.',
    imageUrl: require('../assets/bika/Inspections.png')
  },
  {
    name: 'News',
    description: 'Compile news articles to keep your employees appraised.',
    imageUrl: require('../assets/bika/News.png')
  },

  {
    name: 'Surveys',
    description: 'Get employee feedback by compiling surveys and distributing them to your organization.',
    imageUrl: require('../assets/bika/Surveys.png')
  },
  {
    name: 'Training',
    description: 'Upskill your employees by creating courses that need to be completed by specific areas of your organisation.',
    imageUrl: require('../assets/bika/Training.png')
  },
  {
    name: 'Visitor Management',
    description: 'Manage your visitors and prerequisites to be completed before a visitor arrives. Connect this with induction, training and covid screenings.',
    imageUrl: require('../assets/bika/Visitor-2.png')
  }

]

const games = [
  {
    name: 'Mining sector',
    description: 'Mines make use of the digital COVID screening and integration into their time and attendance.',
    imageUrl: require('../assets/images/photo-1523848309072-c199db53f137.jpg')
    // href: '/#/Incenti5',
  },
  {
    name: 'Retail',
    description: 'Retailers have used the product to do KPI/Target scoring and reviews.',
    imageUrl: require('../assets/images/photo-1604719312566-8912e9227c6a.jpg')
  },
  {
    name: 'Business',
    description: 'Customers have used the product to encapsulate additional systems into this centralised hub.',
    imageUrl: require('../assets/images/type-away-numero-dos.jpg')
  }
]

const faqs = [
  {
    id: 1,
    question: "1.	Why can't I login?",
    answer: 'Please be sure to use one of the following as your User ID: Email Address, Cell phone Number, Employee Number, ID Number or Passport Number.'
  },
  {
    id: 2,
    question: '2.	Forgot your password?',
    answer: 'Click the forgot password link and select one of the password recovery options. There are three ways to reset your password: SMS, Email, or answer security questions.'
  },
  {
    id: 3,
    question: '3.	Why am I inactive?',
    answer: 'Please contact the system administrator to assist. Reasons for an inactive account could be - i.)	You no longer work for the company; ii)	Your account has been locked.'
  },
  {
    id: 4,
    question: '4.	What is Bika?',
    answer: 'BIKA is a corporate communications platform and feedback tool. Your organisation has invited you to make use of this platform to better communicate to its employees.'
  }
  // More questions...
]

const contact = [
  {
    name: 'Phone',
    description: ' +27 (0)11 312 9409',
    icon: PhoneIcon
  },
  {
    name: 'E-Mail',
    description: 'info@devinspire.co.za',
    href: 'mailto:info@devinspire.co.za',
    icon: MailIcon
  },

  {
    name: 'Address',
    description: 'Midrand, South Africa',
    icon: LocationMarkerIcon
  }
]

const footerNavigation = {
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/DevInspireTeam',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d:
                'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/devinspiresa/',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d:
                'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/DevInspireSA',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              d:
                'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84'
            })
          ])
      })
    }
  ]
}

export default {
  name: 'FAQ',
  mounted: function () {
    window.scrollTo(0, 0)

    const tl = gsap.timeline({ defaults: { duration: 0.7, opacity: 0 } })// ease: Tween.Back.easeOut.config(2),
    tl.from('#b', { delay: 2, scale: 0.7, transformOrigin: 'center', rotate: 10 }, '-=.2')
    tl.from('#i', { delay: 0, scale: 0.7, transformOrigin: 'center', rotate: 10 }, '-=.2')
    tl.from('#k', { delay: 0, scale: 0.7, transformOrigin: 'center', rotate: 10 }, '-=.2')
    tl.from('#a', { delay: 0, scale: 0.7, transformOrigin: 'center', rotate: 10 }, '-=.2')
    tl.from('#chatBubble_group', { delay: 0, scale: 0.9, transformOrigin: 'center', rotate: 10, ease: 'Tween.Back.easeOut.config(2)', duration: 1 }, '-=.1')
    tl.to('#chatBubble_group', { opacity: 100, duration: 2.5 })
    tl.yoyo(true).repeat(-1).play(0.5)

    gsap.from('#gsapHeaderImage', { x: 50, duration: 2 })
    gsap.from('#gsapHeader', { stagger: { amount: 1, from: 'left' }, x: -200, opacity: 0, duration: 1 })
    gsap.from('#gsapDev', { stagger: { amount: 1, from: 'left', grid: 'auto' }, opacity: 0, duration: 2 })

    gsap.from('#gsapModules', { scrollTrigger: { trigger: '#gsapModules', start: 'top bottom', end: 'bottom bottom', toggleActions: 'play none none none' }, stagger: { amount: 1, from: 'center', grid: 'auto' }, y: -200, opacity: 0, duration: 1 })
    gsap.from('#gsapFooter', { scrollTrigger: { trigger: '#gsapFooter', start: 'top bottom', end: 'bottom bottom', toggleActions: 'play none reverse none' }, stagger: { amount: 1, from: 'left' }, y: -200, opacity: 0, duration: 1 })
  },
  components: {
    PhoneIcon,
    MailIcon,
    LocationMarkerIcon
  },
  setup () {
    return {
      navigation,
      Components,
      icons,
      games,
      faqs,
      contact,
      footerNavigation
    }
  }

}
