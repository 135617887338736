<template>
  <div class="bg-white">
    <div class="relative overflow-hidden">

        <div class="bg-black pt-6">
          <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
            <div class="md:flex items-center flex-1">
              <div class="flex items-center w-full">
                <router-link :to="'/'">
                  <span class="sr-only">Workflow</span>
                  <img class="h-10 w-auto sm:h-16" src="..\assets\logos\DevInspire_Logo.png" alt="" />
                </router-link>
              </div>

              <div class=" md:flex md:ml-10 text-gray-300" >
                <router-link :to="'/#productsBlock'" class="text-base font-bold hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Products</router-link>&nbsp;
                <span class="text-base font-medium text-yellow-500">></span>&nbsp;
                <router-link to="/incenti5" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;" >Incenti5</router-link>&nbsp;
                <span class="w-2">&middot;</span><router-link to="/Walkabout" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Walkabout</router-link>&nbsp;
                <span class="w-2">&middot;</span><router-link to="/Bika" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Bika</router-link>&nbsp;
                <span class="w-2">&middot;</span><span class="text-base font-bold text-yellow-500 ">Tokaly</span>&nbsp;
                <span class="w-2">&middot;</span><router-link to="/VExpoLab" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">VExpoLab</router-link>
                <span class="w-2">&middot;</span><router-link to="/BoomRuleAccess" class="text-base font-medium hover:text-white w-36" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Boom Rule Access</router-link>
              </div>

            </div>
          </nav>
        </div>

      <main>
        <div class="pt-10 bg-gray-800 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div class="mx-auto max-w-7xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div class="lg:py-24">
                  <h1 class="mt-4 text-4xl tracking-tight font-extrabold sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-700 sm:pb-5" id="gsapHeader">
                    Tokaly.
                  </h1>
                  <div class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl" id="gsapHeader">
                    Marketing through Gamification </div>
                </div>
              </div>
              <div class="mt-12 mb-0 sm:mb-48 lg:m-0 lg:relative">
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 ">
                  <div class="container" id="gsapHeaderImage">

                    <svg id="Tokaly" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 300">
                      <polygon id="PolygonYellow" points="76.78 78.55 76.78 149.28 138.03 113.92 76.78 78.55" fill="#f8dc69"/>
                      <polygon id="PolygonPink" points="103.98 149.34 83.37 217 152.27 201.02 103.98 149.34" fill="#e684c3"/>
                      <polygon id="PolygonBlue" points="56.33 120.39 8 172.03 76.89 188.07 56.33 120.39" fill="#7ce6d7"/>
                      <g id="TokalyText" data-name="Tokaly">
                        <path d="M215.81,112.47H193v-8.22h55.44v8.22H225.58v66.66h-9.77Z" fill="#fff"/>
                        <path d="M297.24,151.8c0,19.89-13.78,28.55-26.78,28.55-14.55,0-25.77-10.66-25.77-27.66,0-18,11.78-28.55,26.66-28.55C286.8,124.14,297.24,135.36,297.24,151.8Zm-42.66.55c0,11.78,6.77,20.67,16.33,20.67,9.33,0,16.33-8.78,16.33-20.89,0-9.11-4.55-20.66-16.11-20.66S254.58,142.13,254.58,152.35Z" fill="#fff"/>
                        <path d="M319.23,150h.23c1.33-1.89,3.22-4.22,4.77-6.11L340,125.36h11.78L331,147.47l23.66,31.66H342.79l-18.56-25.78-5,5.56v20.22h-9.66V100.25h9.66Z" fill="#fff"/>
                        <path d="M393.44,179.13l-.77-6.78h-.34a20,20,0,0,1-16.44,8c-10.89,0-16.44-7.66-16.44-15.44,0-13,11.55-20.11,32.33-20V143.8c0-4.44-1.23-12.44-12.22-12.44a26.75,26.75,0,0,0-14,4l-2.22-6.45A33.32,33.32,0,0,1,381,124.14c16.44,0,20.44,11.22,20.44,22v20.11a75.39,75.39,0,0,0,.89,12.89ZM392,151.69c-10.67-.22-22.78,1.66-22.78,12.11,0,6.33,4.23,9.33,9.23,9.33a13.37,13.37,0,0,0,13-9A10,10,0,0,0,392,161Z" fill="#fff"/>
                        <path d="M417.22,100.25H427v78.88h-9.77Z" fill="#fff"/>
                        <path d="M447,125.36l11.77,31.77c1.23,3.56,2.56,7.78,3.45,11h.22c1-3.22,2.11-7.33,3.45-11.22l10.66-31.55h10.33l-14.66,38.33c-7,18.44-11.78,27.88-18.44,33.66a26.49,26.49,0,0,1-12,6.33l-2.45-8.22a25.79,25.79,0,0,0,8.56-4.78,29.64,29.64,0,0,0,8.22-10.88,7.13,7.13,0,0,0,.78-2.34,8.7,8.7,0,0,0-.67-2.55l-19.89-49.55Z" fill="#fff"/>
                      </g>
                    </svg>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Tokaly Presentation -->
        <div class="relative bg-gray-700 py-16 sm:py-24 lg:py-32">
          <div class="relative">

              <ul role="list" class="space-y-3">
              <li v-for="item in items" :key="item.name" class="overflow-hidden px-6 py-4">

                <div class="max-w-7xl mx-auto ">
                      <!-- We've used 3xl here, but feel free to try other max-widths based on your needs -->
                    <div class="max-w-3xl mx-auto">
                      <img class=" w-full object-cover" :src="item.imageUrl" alt="" />
                    </div>
                  </div>

              </li>
            </ul>

          </div>
        </div>

    </main>

      <!-- FOOTER -->
      <footer class="bg-black" aria-labelledby="footer-heading">
        <div class="max-w-7xl mx-auto pt-12 pb-5 px-4 sm:px-6 lg:pt-16 lg:px-8 text-center ">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3" >

                  <div class="flow-root bg-gray-800 rounded-lg p-6" id="gsapFooter">
                      <router-link :to="'/'" @click="scrollToAnchorPoint('top')" >
                      <div style="display: table-cell; text-align: center; vertical-align:middle;">
                        <img src="..\assets\logos\DevInspire_Logo_light.png" alt="Dev Inspire" />
                      </div>
                      </router-link>
                  </div>

                  <div class="flow-root bg-gray-800 rounded-lg px-6 pb-8; " style="text-align: center; " id="gsapFooter" >
                      <div class="relative text-md text-gray-300 font-medium mt-5 m-auto" style="width:230px;">
                        <div style="width: 100%; display: table;  ">
                            <div v-for="item in contact" :key="item.name" style="display: table-row;" >
                              <div style="display: table-cell; text-align:left;vertical-align:middle;" class="pb-3">
                                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                                </div>
                              <div style="display: table-cell; text-align:left;vertical-align:middle; width:200px" class="pb-3">
                                <a :href="item.href">{{ item.description }}</a>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>

                  <div class="flow-root bg-gray-800 rounded-lg p-8" id="gsapFooter">
                     <div class="relative text-md text-gray-200 font-medium mt-2 m-auto" style="width:300px;">
                      <div class="flex space-x-6 md:order-2">
                        <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" class="text-gray-200 hover:text-gray-500">
                          <span class="sr-only">{{ item.name }}</span>
                          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </a>
                      </div>
                      <p class="relative text-md text-gray-400 font-medium mt-5 text-left">
                        &copy; {{this.currentDate}} Dev Inspire. All rights reserved.
                      </p>
                      <p class="relative text-md text-gray-400 font-medium mt-5 text-left">
                        <u><router-link to="/cookiePolicy">Cookie Policy</router-link></u> | <u><a href="PAIA_Manual.pdf" target="_blank">PAIA Manual</a></u>
                      </p>
                     </div>
                  </div>
              </div>
        </div>
      </footer>

    </div>
  </div>
</template>

<script src=".\tokaly2.js"></script>
