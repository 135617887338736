<template>
	<div class=" bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div class="mx-auto max-w-7xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">

              <div class="mt-0 lg:mt-18 lg:relative">
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 ">

                  <!--<img id="gsapHeaderImage" class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="../assets/logos/incenti5.svg" alt="" />-->
                  <!-- VExpoLab_pnp_dairy_portrait.png -->
                  <!--Illustration.svg-->
                  <div class="container" id="gsapHeaderImage">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 200">
                      <linearGradient id="circle_00000091708172195953324110000015432020085803933367_" gradientUnits="userSpaceOnUse" x1="350.1977" y1="-544.1176" x2="489.9557" y2="-464.9414" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
						<stop  offset="0" style="stop-color:#FF3131"/>
						<stop  offset="0.77" style="stop-color:#FF7700"/>
						<stop  offset="1" style="stop-color:#FFA100"/>
					</linearGradient>
					<path id="circle" style="fill:url(#circle_00000091708172195953324110000015432020085803933367_);" d="M497,96.4
						c0,47.9-38.9,86.8-86.8,86.8c-29.8,0-57.1-15.1-73-39.8h12.7c14.3,18.4,36.4,29.4,60.3,29.4c42.1,0,76.4-34.3,76.4-76.4
						S452.3,20,410.2,20c-23.3,0-45.5,10.8-59.9,29c-2.5-1.7-5.6-2.6-9.1-2.6c-0.7,0-1.3,0-2,0.1c0.4-0.5,0.7-1,1.1-1.5
						c7.9-10.7,18.3-19.6,30.1-25.8c12.2-6.3,26-9.7,39.8-9.7C458.1,9.6,497,48.6,497,96.4L497,96.4z"/>
					<g id="PromotionalSystem">

							<linearGradient id="P_00000106847481922556398350000014367006945998400174_" gradientUnits="userSpaceOnUse" x1="12.3474" y1="-591.963" x2="12.3474" y2="-569.908" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>
						<path id="P" style="fill:url(#P_00000106847481922556398350000014367006945998400174_);" d="M5.5,156.7c0.8-0.2,1.6-0.3,2.6-0.4
							c0.9-0.1,1.8-0.1,2.7-0.1c1,0,1.9,0.1,2.9,0.3s1.9,0.5,2.7,1c0.8,0.5,1.5,1.2,2,2.1c0.5,0.9,0.8,2,0.8,3.3s-0.2,2.5-0.7,3.4
							c-0.5,0.9-1.1,1.7-1.9,2.2c-0.8,0.6-1.7,1-2.7,1.2c-1,0.2-2.1,0.4-3.1,0.4h-0.5c-0.2,0-0.5,0-0.7,0c-0.3,0-0.5,0-0.8,0
							c-0.2,0-0.4,0-0.5-0.1v8.3H5.5L5.5,156.7L5.5,156.7z M10.9,158.4c-0.5,0-1.1,0-1.6,0c-0.5,0-0.9,0.1-1.2,0.2v9
							c0.1,0,0.3,0.1,0.5,0.1c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0,0.7,0h0.5c0.7,0,1.4-0.1,2.1-0.2c0.7-0.1,1.3-0.4,1.9-0.7s1-0.9,1.4-1.5
							c0.3-0.6,0.5-1.5,0.5-2.5c0-0.9-0.2-1.6-0.5-2.1c-0.3-0.6-0.7-1-1.3-1.4c-0.5-0.3-1.1-0.6-1.8-0.7
							C12.2,158.5,11.5,158.5,10.9,158.4L10.9,158.4z"/>

							<linearGradient id="r_00000080186573171031871590000000770577276530119597_" gradientUnits="userSpaceOnUse" x1="25.5952" y1="-591.9678" x2="25.5952" y2="-576.079" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>
						<path id="r" style="fill:url(#r_00000080186573171031871590000000770577276530119597_);" d="M29,165.1c-0.6-0.2-1.2-0.3-1.8-0.3
							c-0.9,0-1.6,0.2-2.1,0.7c-0.5,0.5-0.8,1.1-1,1.9v10.9h-2.5v-15.6h1.7l0.5,1.9H24c0.4-0.7,0.9-1.3,1.4-1.6c0.5-0.4,1.3-0.6,2.1-0.6
							c0.6,0,1.3,0.1,2,0.3L29,165.1L29,165.1z"/>

							<linearGradient id="o_00000107564092214212728490000001294722666351023263_" gradientUnits="userSpaceOnUse" x1="38.1905" y1="-592.3454" x2="38.1905" y2="-576.0168" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>

							<path id="o_00000120522457161578756250000006209226245327448743_" style="fill:url(#o_00000107564092214212728490000001294722666351023263_);" d="
							M31.4,170.5c0-2.6,0.6-4.7,1.8-6.1c1.2-1.4,2.9-2.1,5.1-2.1c1.2,0,2.2,0.2,3,0.6c0.9,0.4,1.6,0.9,2.1,1.6c0.6,0.7,1,1.6,1.2,2.6
							c0.3,1,0.4,2.1,0.4,3.4c0,2.6-0.6,4.7-1.8,6.1c-1.2,1.4-2.9,2.1-5.1,2.1c-1.2,0-2.2-0.2-3-0.6c-0.9-0.4-1.6-0.9-2.1-1.6
							c-0.6-0.7-1-1.6-1.2-2.6C31.5,172.8,31.4,171.7,31.4,170.5z M34,170.5c0,0.8,0.1,1.5,0.2,2.3c0.2,0.7,0.4,1.4,0.7,1.9
							c0.3,0.5,0.8,1,1.3,1.3c0.5,0.3,1.2,0.5,2,0.5c2.8,0,4.2-2,4.2-6c0-0.8-0.1-1.6-0.2-2.3c-0.2-0.7-0.4-1.4-0.7-1.9
							c-0.3-0.5-0.8-1-1.3-1.3c-0.5-0.3-1.2-0.5-2-0.5C35.4,164.5,34,166.5,34,170.5z"/>

							<linearGradient id="m_00000067217897505850753860000002539207044747295367_" gradientUnits="userSpaceOnUse" x1="59.3158" y1="-591.9678" x2="59.3158" y2="-576.0121" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>

							<path id="m_00000130628173790304491120000016695321285446744211_" style="fill:url(#m_00000067217897505850753860000002539207044747295367_);" d="
							M58.1,178.3v-8.8c0-0.8,0-1.6-0.1-2.2c-0.1-0.6-0.2-1.1-0.4-1.5c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.9-0.3-1.5-0.3
							c-1,0-1.7,0.3-2.4,0.9s-1.1,1.3-1.3,2.2v10.7h-2.5v-15.6h1.7l0.5,1.9h0.1c0.6-0.7,1.2-1.2,1.9-1.6c0.7-0.4,1.7-0.6,2.9-0.6
							c1,0,1.8,0.2,2.4,0.6c0.6,0.4,1.1,1.1,1.5,2c0.5-0.8,1.1-1.4,2-1.9c0.8-0.5,1.8-0.7,2.8-0.7c0.9,0,1.6,0.1,2.2,0.3
							c0.6,0.2,1.1,0.5,1.5,1c0.4,0.5,0.7,1.2,0.9,2s0.3,1.9,0.3,3.2v9.3h-2.5v-9.4c0-0.7,0-1.4-0.1-1.9c-0.1-0.5-0.2-1-0.4-1.4
							c-0.2-0.4-0.5-0.6-0.9-0.8c-0.4-0.2-0.9-0.3-1.5-0.3c-1,0-1.8,0.3-2.4,0.9c-0.6,0.6-1,1.4-1.2,2.5v10.4L58.1,178.3L58.1,178.3z"/>

							<linearGradient id="o_00000080208509924319078630000009865220205167821743_" gradientUnits="userSpaceOnUse" x1="80.1877" y1="-592.3454" x2="80.1877" y2="-576.0168" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>

							<path id="o_00000180339057894335568460000018275778111492227243_" style="fill:url(#o_00000080208509924319078630000009865220205167821743_);" d="
							M73.4,170.5c0-2.6,0.6-4.7,1.8-6.1c1.2-1.4,2.9-2.1,5.1-2.1c1.2,0,2.2,0.2,3,0.6c0.9,0.4,1.6,0.9,2.1,1.6c0.6,0.7,1,1.6,1.2,2.6
							c0.3,1,0.4,2.1,0.4,3.4c0,2.6-0.6,4.7-1.8,6.1c-1.2,1.4-2.9,2.1-5.1,2.1c-1.2,0-2.2-0.2-3-0.6c-0.9-0.4-1.6-0.9-2.1-1.6
							c-0.6-0.7-1-1.6-1.2-2.6C73.5,172.8,73.4,171.7,73.4,170.5z M76,170.5c0,0.8,0.1,1.5,0.2,2.3c0.2,0.7,0.4,1.4,0.7,1.9
							c0.3,0.5,0.8,1,1.3,1.3c0.5,0.3,1.2,0.5,2,0.5c2.8,0,4.2-2,4.2-6c0-0.8-0.1-1.6-0.2-2.3c-0.2-0.7-0.4-1.4-0.7-1.9
							c-0.3-0.5-0.8-1-1.3-1.3c-0.5-0.3-1.2-0.5-2-0.5C77.4,164.5,76,166.5,76,170.5z"/>

							<linearGradient id="t_00000179621895377204593410000011314763372160423061_" gradientUnits="userSpaceOnUse" x1="93.8179" y1="-592.3358" x2="93.8179" y2="-572.5896" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>

							<path id="t_00000105394914155298386720000006196136015146386585_" style="fill:url(#t_00000179621895377204593410000011314763372160423061_);" d="
							M88.9,162.7h2.4v-3.1l2.5-0.7v3.8H98v2.2h-4.2v8.6c0,1.1,0.1,1.8,0.4,2.3c0.3,0.4,0.7,0.7,1.4,0.7c0.5,0,1-0.1,1.4-0.2
							s0.8-0.3,1.2-0.5l0.6,1.9c-0.6,0.3-1.2,0.5-1.8,0.7c-0.7,0.2-1.4,0.2-2.2,0.2c-1.3,0-2.2-0.4-2.7-1.1c-0.5-0.7-0.8-1.9-0.8-3.6
							v-9.1h-2.4L88.9,162.7L88.9,162.7z"/>

							<linearGradient id="i_00000163761334085090432720000014568330105274223276_" gradientUnits="userSpaceOnUse" x1="103.2608" y1="-591.9725" x2="103.2608" y2="-569.9128" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>
						<path id="i" style="fill:url(#i_00000163761334085090432720000014568330105274223276_);" d="M101.5,158c0-0.5,0.2-0.9,0.5-1.2
							c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.3,0.5c0.4,0.3,0.5,0.8,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2c-0.4,0.3-0.8,0.5-1.3,0.5
							c-0.5,0-0.9-0.2-1.2-0.5C101.7,158.8,101.5,158.4,101.5,158z M102,162.7h2.5v15.6H102V162.7z"/>

							<linearGradient id="o_00000109746659537459903280000011607095144845064580_" gradientUnits="userSpaceOnUse" x1="115.7988" y1="-592.3454" x2="115.7988" y2="-576.0168" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>
						<path id="o" style="fill:url(#o_00000109746659537459903280000011607095144845064580_);" d="M109,170.5c0-2.6,0.6-4.7,1.8-6.1
							c1.2-1.4,2.9-2.1,5.1-2.1c1.2,0,2.2,0.2,3,0.6c0.9,0.4,1.6,0.9,2.1,1.6c0.6,0.7,1,1.6,1.2,2.6c0.3,1,0.4,2.1,0.4,3.4
							c0,2.6-0.6,4.7-1.8,6.1c-1.2,1.4-2.9,2.1-5.1,2.1c-1.2,0-2.2-0.2-3-0.6c-0.9-0.4-1.6-0.9-2.1-1.6c-0.6-0.7-1-1.6-1.2-2.6
							C109.1,172.8,109,171.7,109,170.5L109,170.5z M111.6,170.5c0,0.8,0.1,1.5,0.2,2.3s0.4,1.4,0.7,1.9c0.3,0.5,0.8,1,1.3,1.3
							c0.5,0.3,1.2,0.5,2,0.5c2.8,0,4.2-2,4.2-6c0-0.8-0.1-1.6-0.2-2.3c-0.2-0.7-0.4-1.4-0.7-1.9c-0.3-0.5-0.8-1-1.3-1.3
							c-0.5-0.3-1.2-0.5-2-0.5C113,164.5,111.6,166.5,111.6,170.5z"/>

							<linearGradient id="n_00000046328222467757675990000013286078564667858566_" gradientUnits="userSpaceOnUse" x1="132.7941" y1="-591.9678" x2="132.7941" y2="-576.0121" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>
						<path id="n" style="fill:url(#n_00000046328222467757675990000013286078564667858566_);" d="M136.5,178.3v-8.9
							c0-1.6-0.2-2.8-0.7-3.7c-0.5-0.8-1.3-1.2-2.6-1.2c-1.1,0-2,0.3-2.7,0.9c-0.7,0.6-1.2,1.3-1.5,2.2v10.7h-2.5v-15.6h1.7l0.5,1.9h0.1
							c0.5-0.7,1.2-1.2,2-1.6c0.8-0.4,1.8-0.6,3-0.6c0.9,0,1.6,0.1,2.2,0.3c0.6,0.2,1.2,0.5,1.6,1c0.4,0.5,0.7,1.2,1,2
							c0.2,0.8,0.3,1.9,0.3,3.2v9.4L136.5,178.3L136.5,178.3z"/>

							<linearGradient id="a_00000168823681914842339440000007364444553593425581_" gradientUnits="userSpaceOnUse" x1="148.4917" y1="-592.2163" x2="148.4917" y2="-576.1364" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>
						<path id="a" style="fill:url(#a_00000168823681914842339440000007364444553593425581_);" d="M143.3,163.8c0.8-0.5,1.7-0.8,2.8-1.1
							c1-0.2,2.1-0.3,3.3-0.3c1.1,0,1.9,0.1,2.6,0.4c0.7,0.3,1.2,0.7,1.5,1.1c0.3,0.5,0.6,1,0.7,1.5c0.1,0.6,0.2,1.1,0.2,1.7
							c0,1.2,0,2.5-0.1,3.6c-0.1,1.2-0.1,2.3-0.1,3.4c0,0.8,0,1.5,0.1,2.2c0.1,0.7,0.2,1.3,0.3,1.9h-1.8l-0.7-2.2h-0.2
							c-0.2,0.3-0.4,0.6-0.7,0.9c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.2-0.9,0.4-1.4,0.5c-0.5,0.1-1.1,0.2-1.8,0.2s-1.3-0.1-1.9-0.3
							c-0.6-0.2-1.1-0.5-1.5-0.9c-0.4-0.4-0.7-0.8-1-1.4s-0.4-1.2-0.4-1.8c0-0.9,0.2-1.7,0.6-2.3c0.4-0.6,0.9-1.1,1.6-1.5
							c0.7-0.4,1.5-0.6,2.4-0.8c0.9-0.2,2-0.2,3.1-0.2h0.9c0.3,0,0.6,0,0.9,0.1c0.1-0.6,0.1-1.2,0.1-1.7c0-1.1-0.2-1.9-0.7-2.4
							c-0.5-0.5-1.3-0.7-2.5-0.7c-0.4,0-0.7,0-1.1,0.1c-0.4,0.1-0.8,0.1-1.2,0.2c-0.4,0.1-0.8,0.2-1.2,0.4c-0.4,0.1-0.7,0.3-1,0.5
							L143.3,163.8L143.3,163.8z M147.8,176.3c0.6,0,1.1-0.1,1.6-0.2c0.5-0.2,0.9-0.4,1.2-0.6c0.3-0.2,0.6-0.5,0.8-0.8
							c0.2-0.3,0.4-0.6,0.5-0.8v-2.6c-0.3,0-0.6,0-0.9,0c-0.3,0-0.6,0-0.9,0c-0.6,0-1.3,0-1.9,0.1c-0.6,0.1-1.2,0.2-1.6,0.4
							c-0.5,0.2-0.8,0.5-1.1,0.8c-0.3,0.3-0.4,0.8-0.4,1.3c0,0.7,0.3,1.3,0.8,1.8C146.3,176.1,147,176.3,147.8,176.3L147.8,176.3z"/>

							<linearGradient id="l_00000161603804524812758360000009580472787452899004_" gradientUnits="userSpaceOnUse" x1="162.3991" y1="-592.3502" x2="162.3991" y2="-570.1661" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>
						<path id="l" style="fill:url(#l_00000161603804524812758360000009580472787452899004_);" d="M161.8,174.4c0,0.7,0.1,1.2,0.4,1.6
							c0.2,0.3,0.6,0.5,1.1,0.5c0.3,0,0.6,0,0.9-0.1c0.3,0,0.7-0.1,1.1-0.3l0.3,2c-0.3,0.2-0.8,0.3-1.4,0.4c-0.6,0.1-1.1,0.2-1.5,0.2
							c-0.9,0-1.7-0.3-2.3-0.8c-0.6-0.5-0.9-1.4-0.9-2.7v-18.7h2.5L161.8,174.4L161.8,174.4z"/>

							<linearGradient id="s_00000121265463145412892250000013997864558177457028_" gradientUnits="userSpaceOnUse" x1="182.3127" y1="-592.355" x2="182.3127" y2="-569.7933" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>

							<path id="s_00000060730947414752267480000009988673151192579515_" style="fill:url(#s_00000121265463145412892250000013997864558177457028_);" d="
							M186.6,172.7c0-0.9-0.3-1.6-0.8-2.1c-0.5-0.5-1.2-1-1.9-1.5c-0.8-0.4-1.6-0.8-2.5-1.2c-0.9-0.4-1.8-0.8-2.5-1.4
							c-0.8-0.5-1.4-1.2-1.9-2c-0.5-0.8-0.8-1.8-0.8-3c0-1.7,0.6-3.1,1.8-4c1.2-1,2.9-1.5,5-1.5c1.3,0,2.4,0.1,3.5,0.3
							c1,0.2,1.8,0.4,2.4,0.7l-0.8,2.3c-0.5-0.2-1.2-0.5-2.1-0.7c-0.9-0.2-2-0.3-3.1-0.3c-1.4,0-2.4,0.3-3.1,0.9c-0.7,0.6-1,1.3-1,2.2
							c0,0.8,0.3,1.5,0.8,2c0.5,0.5,1.2,1,1.9,1.4c0.8,0.4,1.6,0.8,2.5,1.3s1.8,0.9,2.5,1.4s1.4,1.2,1.9,2c0.5,0.8,0.8,1.7,0.8,2.9
							c0,0.9-0.2,1.8-0.5,2.5c-0.3,0.7-0.8,1.4-1.5,1.9c-0.6,0.5-1.4,1-2.3,1.2c-0.9,0.3-2,0.4-3.1,0.4c-1.6,0-2.8-0.1-3.9-0.4
							c-1-0.2-1.8-0.5-2.4-0.8l0.9-2.3c0.5,0.3,1.2,0.5,2.1,0.8c0.9,0.3,2,0.4,3.1,0.4c0.7,0,1.3-0.1,1.9-0.2c0.6-0.1,1.1-0.4,1.6-0.7
							s0.8-0.7,1-1.1C186.4,173.9,186.5,173.4,186.6,172.7L186.6,172.7z"/>

							<linearGradient id="y_00000001665395765638358760000012884095824872377533_" gradientUnits="userSpaceOnUse" x1="197.9075" y1="-598.3586" x2="197.9075" y2="-576.3945" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>
						<path id="y" style="fill:url(#y_00000001665395765638358760000012884095824872377533_);" d="M197.6,172.8l0.9,3h0.2l0.7-3l3-10.1
							h2.5l-4.6,14c-0.4,1-0.7,2-1,3c-0.3,1-0.7,1.8-1.1,2.6c-0.4,0.7-0.9,1.3-1.4,1.8s-1.1,0.7-1.8,0.7c-0.7,0-1.4-0.1-1.8-0.3l0.4-2.2
							c0.3,0.1,0.5,0.2,0.8,0.2c0.6,0,1.1-0.3,1.7-0.9c0.5-0.6,1-1.7,1.3-3.2l-6.3-15.6h2.9L197.6,172.8L197.6,172.8z"/>

							<linearGradient id="s_00000007386443277012088930000001620746233619856555_" gradientUnits="userSpaceOnUse" x1="211.6022" y1="-592.3406" x2="211.6022" y2="-576.0025" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>
						<path id="s" style="fill:url(#s_00000007386443277012088930000001620746233619856555_);" d="M214.4,174c0-0.6-0.2-1.1-0.6-1.5
							c-0.4-0.4-0.8-0.7-1.4-0.9c-0.5-0.2-1.2-0.5-1.8-0.7c-0.7-0.2-1.3-0.5-1.8-0.8s-1-0.8-1.4-1.3c-0.4-0.5-0.6-1.3-0.6-2.2
							c0-1.5,0.4-2.6,1.3-3.3s2-1,3.6-1c1.1,0,2.1,0.1,2.8,0.3c0.8,0.2,1.4,0.4,2,0.7l-0.6,2.1c-0.5-0.2-1.1-0.4-1.7-0.6
							c-0.7-0.2-1.4-0.3-2.2-0.3c-0.9,0-1.6,0.2-2,0.5c-0.4,0.3-0.6,0.9-0.6,1.6c0,0.5,0.2,1,0.6,1.3c0.4,0.3,0.8,0.6,1.4,0.8
							c0.5,0.2,1.2,0.5,1.8,0.7s1.3,0.5,1.8,0.9s1,0.8,1.4,1.4c0.4,0.6,0.6,1.3,0.6,2.3c0,0.7-0.1,1.3-0.3,1.9c-0.2,0.6-0.6,1.1-1,1.5
							c-0.5,0.4-1,0.7-1.7,1c-0.7,0.2-1.5,0.4-2.4,0.4c-1.2,0-2.2-0.1-3-0.3c-0.8-0.2-1.5-0.5-2.1-0.8l0.7-2.1c0.5,0.3,1.1,0.5,1.9,0.7
							c0.8,0.2,1.5,0.3,2.3,0.3c0.9,0,1.6-0.2,2.2-0.5S214.4,175,214.4,174L214.4,174z"/>

							<linearGradient id="t_00000165937344160190344330000009049884737158761353_" gradientUnits="userSpaceOnUse" x1="223.5188" y1="-592.3358" x2="223.5188" y2="-572.5896" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>
						<path id="t" style="fill:url(#t_00000165937344160190344330000009049884737158761353_);" d="M218.6,162.7h2.4v-3.1l2.5-0.7v3.8h4.2
							v2.2h-4.2v8.6c0,1.1,0.1,1.8,0.4,2.3c0.3,0.4,0.7,0.7,1.4,0.7c0.5,0,1-0.1,1.4-0.2c0.4-0.1,0.8-0.3,1.2-0.5l0.6,1.9
							c-0.6,0.3-1.2,0.5-1.8,0.7c-0.7,0.2-1.4,0.2-2.2,0.2c-1.3,0-2.2-0.4-2.7-1.1c-0.5-0.7-0.8-1.9-0.8-3.6v-9.1h-2.4L218.6,162.7
							L218.6,162.7z"/>

							<linearGradient id="e_00000098927938954439527780000011295608864862497425_" gradientUnits="userSpaceOnUse" x1="236.4009" y1="-592.3406" x2="236.4009" y2="-576.0121" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>
						<path id="e" style="fill:url(#e_00000098927938954439527780000011295608864862497425_);" d="M242.3,177c-0.6,0.5-1.4,0.9-2.4,1.2
							c-0.9,0.3-1.9,0.4-3,0.4c-1.2,0-2.2-0.2-3.1-0.6s-1.6-0.9-2.2-1.6c-0.6-0.7-1-1.6-1.2-2.6c-0.3-1-0.4-2.1-0.4-3.4
							c0-2.7,0.6-4.7,1.8-6.1c1.2-1.4,2.9-2.1,5.1-2.1c0.7,0,1.4,0.1,2.1,0.2c0.7,0.2,1.3,0.5,1.9,0.9c0.6,0.4,1,1.1,1.4,1.9
							c0.4,0.8,0.5,1.9,0.5,3.2c0,0.7-0.1,1.4-0.2,2.3h-10c0,0.9,0.1,1.7,0.3,2.4c0.2,0.7,0.5,1.3,0.9,1.8c0.4,0.5,0.9,0.9,1.5,1.1
							c0.6,0.3,1.4,0.4,2.3,0.4c0.7,0,1.4-0.1,2.1-0.4c0.7-0.2,1.2-0.5,1.6-0.9L242.3,177L242.3,177z M236.9,164.5c-1.2,0-2.2,0.3-2.9,1
							s-1.2,1.7-1.3,3.3h7.7c0-1.6-0.3-2.7-0.9-3.3C238.9,164.8,238,164.5,236.9,164.5L236.9,164.5z"/>

							<linearGradient id="m_00000097489122936683466760000010932237772208635283_" gradientUnits="userSpaceOnUse" x1="257.0888" y1="-591.9678" x2="257.0888" y2="-576.0121" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
							<stop  offset="0" style="stop-color:#B3B3B3"/>
							<stop  offset="1" style="stop-color:#CCCCCC"/>
						</linearGradient>
						<path id="m" style="fill:url(#m_00000097489122936683466760000010932237772208635283_);" d="M255.8,178.3v-8.8c0-0.8,0-1.6-0.1-2.2
							c-0.1-0.6-0.2-1.1-0.4-1.5c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.9-0.3-1.5-0.3c-1,0-1.7,0.3-2.4,0.9c-0.6,0.6-1.1,1.3-1.3,2.2v10.7
							h-2.5v-15.6h1.7l0.5,1.9h0.1c0.6-0.7,1.2-1.2,1.9-1.6s1.7-0.6,2.9-0.6c1,0,1.8,0.2,2.4,0.6c0.6,0.4,1.1,1.1,1.5,2
							c0.5-0.8,1.1-1.4,2-1.9c0.8-0.5,1.8-0.7,2.8-0.7c0.9,0,1.6,0.1,2.2,0.3c0.6,0.2,1.1,0.5,1.5,1c0.4,0.5,0.7,1.2,0.9,2
							s0.3,1.9,0.3,3.2v9.3H265v-9.4c0-0.7,0-1.4-0.1-1.9c-0.1-0.5-0.2-1-0.4-1.4c-0.2-0.4-0.5-0.6-0.9-0.8c-0.4-0.2-0.9-0.3-1.5-0.3
							c-1,0-1.8,0.3-2.4,0.9c-0.6,0.6-1,1.4-1.2,2.5v10.4L255.8,178.3L255.8,178.3z"/>
					</g>
					<g id="Incenti">
						<path id="I" class="st18" d="M5.1,52.1h17v87h-17V52.1z"/>
						<path id="n_00000092431161381311902480000011018556923993811890_" class="st18" d="M76.5,139.1v-35.3c0-5.1-0.7-8.7-2.2-10.9
							c-1.4-2.2-3.9-3.4-7.4-3.4c-3.1,0-5.7,0.9-7.8,2.7c-2.1,1.8-3.6,4-4.5,6.6v40.3H38.4V77h12.8l1.9,8.2h0.5c1.9-2.6,4.4-5,7.6-7
							c3.1-2,7.2-3,12.2-3c3.1,0,5.8,0.4,8.2,1.2c2.4,0.8,4.4,2.2,6.1,4.1c1.7,1.9,2.9,4.5,3.7,7.8c0.8,3.3,1.2,7.3,1.2,12.1v38.6
							L76.5,139.1L76.5,139.1z"/>
						<path id="c" class="st18" d="M150.1,135.3c-2.5,1.8-5.5,3.2-9,4.2c-3.5,1-7.1,1.4-10.9,1.4c-5,0-9.2-0.8-12.6-2.4
							c-3.4-1.6-6.2-3.8-8.4-6.6c-2.2-2.9-3.7-6.3-4.7-10.4c-1-4.1-1.4-8.5-1.4-13.4c0-10.5,2.4-18.6,7.1-24.3c4.7-5.7,11.6-8.5,20.6-8.5
							c4.6,0,8.2,0.4,11,1.1c2.8,0.7,5.3,1.7,7.5,2.9l-3.9,13.2c-1.9-0.9-3.8-1.6-5.7-2.1s-4-0.7-6.4-0.7c-4.5,0-7.9,1.5-10.2,4.4
							c-2.3,2.9-3.5,7.6-3.5,14.1c0,2.7,0.3,5.1,0.9,7.3c0.6,2.2,1.4,4.2,2.6,5.8c1.2,1.7,2.7,3,4.5,3.9c1.9,1,4,1.4,6.5,1.4
							c2.7,0,5.1-0.3,7-1.1c1.9-0.7,3.6-1.6,5.1-2.5L150.1,135.3L150.1,135.3z"/>
						<path id="e_00000088109682402900633290000009734211647785410702_" class="st18" d="M207.6,133.8c-2.5,2-5.9,3.7-10.1,5
							c-4.3,1.4-8.8,2.1-13.6,2.1c-10,0-17.4-2.9-22-8.8c-4.6-5.8-7-13.9-7-24c0-10.9,2.6-19.1,7.8-24.6c5.2-5.5,12.6-8.2,22-8.2
							c3.1,0,6.2,0.4,9.2,1.2c3,0.8,5.6,2.2,8,4.1c2.3,1.9,4.2,4.5,5.6,7.7c1.4,3.2,2.1,7.3,2.1,12.1c0,1.7-0.1,3.6-0.3,5.6
							c-0.2,2-0.5,4.1-0.9,6.2h-37.2c0.2,5.2,1.6,9.2,4,11.8c2.4,2.7,6.4,4,11.8,4c3.4,0,6.4-0.5,9.1-1.6c2.7-1,4.7-2.1,6.1-3.2
							L207.6,133.8L207.6,133.8z M184.5,88.2c-4.2,0-7.3,1.3-9.3,3.8c-2,2.5-3.2,5.9-3.6,10.1h23c0.3-4.5-0.3-7.9-2-10.3
							C190.8,89.4,188.1,88.2,184.5,88.2L184.5,88.2z"/>
						<path id="n_00000118354985389170556480000008884352720346634378_" class="st18" d="M259,139.1v-35.3c0-5.1-0.7-8.7-2.2-10.9
							c-1.5-2.2-3.9-3.4-7.4-3.4c-3.1,0-5.7,0.9-7.8,2.7c-2.1,1.8-3.6,4-4.5,6.6v40.3H221V77h12.8l1.9,8.2h0.5c1.9-2.6,4.4-5,7.6-7
							c3.1-2,7.2-3,12.2-3c3.1,0,5.8,0.4,8.2,1.2c2.4,0.8,4.4,2.2,6.1,4.1c1.7,1.9,2.9,4.5,3.7,7.8c0.8,3.3,1.2,7.3,1.2,12.1v38.6
							L259,139.1L259,139.1z"/>
						<path id="t_00000138560818287084029480000009368113149333714869_" class="st18" d="M282.2,77h8.6V65.3l16.2-4.6V77h15.2v13.7H307
							v23.9c0,4.3,0.4,7.4,1.3,9.3s2.5,2.8,4.9,2.8c1.7,0,3.1-0.2,4.3-0.5c1.2-0.3,2.5-0.8,4-1.5l2.9,12.4c-2.2,1.1-4.8,2-7.8,2.7
							s-6,1.1-9.1,1.1c-5.7,0-9.9-1.5-12.6-4.4c-2.7-2.9-4-7.8-4-14.5V90.7h-8.6L282.2,77L282.2,77z"/>
						<path id="i_00000072258860948382391690000015167358937350294941_" class="st18" d="M331.1,59.5c0-2.4,0.9-4.5,2.7-6.2
							c1.8-1.7,4.2-2.5,7.4-2.5c3.1,0,5.7,0.9,7.6,2.5c1.9,1.7,2.9,3.7,2.9,6.2s-1,4.4-2.9,6c-1.9,1.6-4.5,2.4-7.6,2.4
							c-3.2,0-5.6-0.8-7.4-2.4C332,63.9,331.1,61.9,331.1,59.5z M333.4,77h16.2v62.1h-16.2V77L333.4,77z"/>
					</g>
					<linearGradient id="_x35__00000000186560042283791300000000568550154481816737_" gradientUnits="userSpaceOnUse" x1="392.0281" y1="-517.0114" x2="437.7203" y2="-492.3082" gradientTransform="matrix(1 0 0 -1 0 -413.7)">
						<stop  offset="0" style="stop-color:#CCCCCC"/>
						<stop  offset="0.9" style="stop-color:#F2F2F2"/>
					</linearGradient>
					<path id="_x35_" style="fill:url(#_x35__00000000186560042283791300000000568550154481816737_);" d="M405.3,126.6
						c5.5,0,9.7-1.3,12.6-4c2.9-2.6,4.3-6.3,4.3-10.9c0-5-1.7-8.6-5.1-10.9c-3.4-2.3-8.2-3.5-14.5-3.5l-9.8,0.4V52.1h42.7v15.2h-28.8
						v16.8l5.1-0.5c4.1,0.2,7.7,0.9,11,2.2c3.3,1.3,6.1,3.2,8.4,5.5c2.3,2.4,4.1,5.2,5.3,8.6c1.2,3.4,1.9,7.1,1.9,11.2
						c0,4.9-0.8,9.2-2.5,12.9c-1.7,3.7-4,6.8-6.9,9.3c-2.9,2.5-6.4,4.3-10.4,5.6c-4,1.2-8.3,1.9-13,1.9c-3.7,0-7.3-0.3-10.6-0.9
						c-3.4-0.6-6.2-1.5-8.5-2.5l4.3-13.4c1.9,0.8,4,1.5,6.3,1.9C399.3,126.4,402.1,126.6,405.3,126.6L405.3,126.6z"/>
					</svg>

                  </div>

                </div>
              </div>

              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center pb-10 mt-5 lg:pb-0 lg:mt-0">
                <div class="lg:py-24">
                  <!-- <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span class="block" id="gsapHeader">Incenti5</span>
                    <span class="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-amber-500 to-red-500 sm:pb-5" id="gsapHeader">Promotional System</span>
                  </h1> -->
                  	<div class="text-base text-gray-200 sm:text-xl lg:text-lg xl:text-xl" id="gsapHeader">
                   		Incenti5 is a complete promotional system designed to Increase Loyalty through rewards and incentives.  Increased turnover is driven through managed promotions and customer incentives.
					</div>
                </div>
              </div>

            </div>
          </div>
        </div>
</template>