<template>
  <div class="bg-white" ref="top">
    <div class="relative overflow-hidden">

        <div class="bg-black pt-6">
          <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
            <div class="flex items-center flex-1">
              <div class="flex items-center justify-between md:w-auto">
                  <span class="sr-only">DevInspire</span>
                  <img class="h-10 w-auto sm:h-16" src="..\assets\logos\DevInspire_Logo.png" alt="" style="min-width: 130px" />
              </div>
              <div class="flex md:ml-10 text-gray-300 w-96 ">

                <div class="pl-4" ><a class="text-base font-medium hover:text-white" @click="scrollToAnchorPoint('productsBlock')" style="cursor: pointer; text-decoration-line: underline;text-decoration-color: darkslategrey;" >Products</a></div>
                <div class="pl-4"><router-link to="/about-us" class="text-base font-medium hover:text-white " style="text-decoration-line: underline;text-decoration-color: darkslategrey;">About Us</router-link></div>
                <div class="pl-4"><router-link to="/contact-us" class="text-base font-medium hover:text-white " style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Contact Us</router-link></div>
              </div>
            </div>
          </nav>
        </div>

      <main>
        <div class=" bg-gray-900 lg:pb-14 lg:overflow-hidden">
          <div class="mx-auto max-w-7xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div >

                  <h1 class="pb-3 tracking-tight font-extrabold text-white text-4xl sm:text-6xl  xl:text-6xl">
                    <span class="block bg-clip-text text-transparent sm:text-9xl xl:text-9xl bg-gradient-to-r from-amber-500 to-red-500" id="gsapHeader">Develop</span>
                    <span class="block " id="gsapHeader">business advantage solutions</span>
                    <span class=" block bg-clip-text text-transparent bg-gradient-to-r from-amber-500 to-red-500 sm:pb-5" id="gsapHeader">to boost your business</span>
                  </h1>
                  <!--<p class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                    We create solutions for getting competitive
                  </p>-->
                  <div class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl" id="gsapHeader">
                    Our mission is to stand as the foremost development partner and digital promotional collaborator, offering unmatched expertise and innovative solutions that drive success and growth for our clients.
                  </div>

                </div>
              </div>
              <div class="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0" >
                  <!-- <img class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="../assets/images/circle_orangeGrad.svg" alt="" id="gsapHeaderImage" /> -->
                  <!-- <logo-svg /> -->
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">

                      <g id="CircleBig">
                        <g>
                          <defs>
                            <ellipse id="SVGID_1_" cx="253.3" cy="259.3" rx="241.2" ry="251.1"/>
                          </defs>
                          <clipPath id="SVGID_00000175321163492509818470000003996024875876001153_">
                            <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
                          </clipPath>
                            <linearGradient id="SVGID_00000006701603617012083530000005921839333719833526_" gradientUnits="userSpaceOnUse" x1="-766.1763" y1="-0.5064" x2="-765.5112" y2="-0.5064" gradientTransform="matrix(-754.9999 754.9999 -754.9999 -754.9999 -578340.9375 578088.875)">
                            <stop  offset="0" style="stop-color:#EB9B22"/>
                            <stop  offset="1.373630e-03" style="stop-color:#EB9B22"/>
                            <stop  offset="1" style="stop-color:#FF5454"/>
                          </linearGradient>
                            <polygon style="clip-path:url(#SVGID_00000175321163492509818470000003996024875876001153_);fill:url(#SVGID_00000006701603617012083530000005921839333719833526_);" points="
                            735.6,259.3 253.3,761.5 -229.1,259.3 253.3,-242.9 		"/>
                        </g>
                      </g>
                      <g id="CircleTop">
                        <g>
                          <defs>
                            <path id="SVGID_00000145752145227068535760000017476215661384526725_" d="M11.5,79.8c0,30.2,23.5,54.6,52.5,54.6
                              s52.5-24.5,52.5-54.6S92.9,25.2,63.9,25.2S11.5,49.6,11.5,79.8"/>
                          </defs>
                          <clipPath id="SVGID_00000021821173340026739440000007107670352653321624_">
                            <use xlink:href="#SVGID_00000145752145227068535760000017476215661384526725_"  style="overflow:visible;"/>
                          </clipPath>
                            <linearGradient id="SVGID_00000139257396346836576680000009590830578332234676_" gradientUnits="userSpaceOnUse" x1="-767.2167" y1="-3.3141" x2="-766.4429" y2="-3.3141" gradientTransform="matrix(1.1093 211.8538 -211.8538 1.1093 212.4412 162528.0156)">
                            <stop  offset="0" style="stop-color:#CF59C4"/>
                            <stop  offset="1.373630e-03" style="stop-color:#CF59C4"/>
                            <stop  offset="1" style="stop-color:#8766D1"/>
                          </linearGradient>
                            <polygon style="clip-path:url(#SVGID_00000021821173340026739440000007107670352653321624_);fill:url(#SVGID_00000139257396346836576680000009590830578332234676_);" points="
                            116.4,24.6 117,134.4 11.5,135 10.9,25.2 		"/>
                        </g>
                      </g>
                      <g id="CircleBottom">
                        <g>
                          <defs>
                            <path id="SVGID_00000067928584012977671810000011733769187668243867_" d="M384.8,428.9c0,30.3,23.7,54.9,53,54.9
                              c29.2,0,53-24.6,53-54.9s-23.7-54.9-53-54.9C408.5,374,384.8,398.6,384.8,428.9"/>
                          </defs>
                          <clipPath id="SVGID_00000121259770507113698710000003141416480787769531_">
                            <use xlink:href="#SVGID_00000067928584012977671810000011733769187668243867_"  style="overflow:visible;"/>
                          </clipPath>

                            <linearGradient id="SVGID_00000098929679987479263490000003619188046895370888_" gradientUnits="userSpaceOnUse" x1="-755.8384" y1="15.1237" x2="-753.999" y2="15.1237" gradientTransform="matrix(35.2541 -48.5232 48.5232 35.2541 26312.127 -36727.6133)">
                            <stop  offset="0" style="stop-color:#8766D1"/>
                            <stop  offset="1.054950e-02" style="stop-color:#8766D1"/>
                            <stop  offset="1" style="stop-color:#6BA1F2"/>
                          </linearGradient>

                            <polygon style="clip-path:url(#SVGID_00000121259770507113698710000003141416480787769531_);fill:url(#SVGID_00000098929679987479263490000003619188046895370888_);" points="
                            334.6,445.9 421.2,321.6 540.8,411.9 454,536.2 		"/>
                        </g>
                      </g>
                      <linearGradient id="donut_00000085944417271086016130000012371218167796214680_" gradientUnits="userSpaceOnUse" x1="438.3569" y1="571.3728" x2="271.8263" y2="382.0081" gradientTransform="matrix(1 0 0 -1 0 514)">
                        <stop  offset="0" style="stop-color:#6BA1F2"/>
                        <stop  offset="0.826" style="stop-color:#BF65CB"/>
                        <stop  offset="0.9782" style="stop-color:#CF59C4"/>
                      </linearGradient>
                      <path id="donut" style="fill:url(#donut_00000085944417271086016130000012371218167796214680_);" d="M355.1-89.4
                        c-69.2,0-125.3,56.7-125.3,126.7S285.9,164,355.1,164s125.3-56.7,125.3-126.7C480.4-32.6,424.3-89.4,355.1-89.4z M355.1,118.6
                        c-44.4,0-80.3-36.4-80.3-81.2c0-44.9,36-81.2,80.3-81.2s80.3,36.4,80.3,81.2C435.4,82.2,399.5,118.6,355.1,118.6z"/>
                      </svg>

                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Development section -->
        <!-- <div class="relative bg-gray-50 py-16 ">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl ">
              <h2 class="text-base font-semibold tracking-wider text-yellow-600 uppercase"></h2>
              <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                What does your business need?
              </p>
              <p class="mt-5 mx-auto max-w-prose text-xl text-gray-500">
                We strive to create products and systems that make a difference using the latest technologies and methodologies.
              </p>
            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl " id="gsapDev">
              <div v-for="item in Dev" :key="item.id" class="flex flex-col rounded-lg shadow-lg overflow-hidden bg-white" >

                <div class="flex-shrink-0">
                  <img class="h-48 w-full object-cover" :src="item.imageUrl" alt="" />
                </div>
                <div class="flex-1  p-6 flex flex-col justify-between">
                  <div class="flex-1">
                    <p class="text-xl font-semibold text-gray-900">
                      {{ item.title }}
                    </p>
                    <p class="mt-3 text-base text-gray-500">
                      {{ item.preview }}
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div> -->

        <!-- Development section -->
        <div class="relative bg-gray-700 py-16 ">
          <div class="relative">

			<div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl ">

			  <p class="text-4xl font-extrabold  tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
                What does your business need?&nbsp;
              </p>
              <p class="mx-auto max-w-prose text-lg text-gray-200">
              We strive to create products and systems that make a difference using the latest technologies and methodologies.</p>
            </div>

            <div class="mt-12 mx-auto max-w-7xl px-4" >




<!-- Generator: Adobe Illustrator 27.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 2155.7 1080" style="enable-background:new 0 0 2155.7 1080;" xml:space="preserve">
   <rect class="st0" width="2155.7" height="1080.1"/>
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="845.0854" y1="3496.6001" x2="2284.7366" y2="3496.6001" gradientTransform="matrix(1 0 0 -1 0 4006)">
	<stop  offset="0" style="stop-color:#CF59C4"/>
	<stop  offset="1.373630e-03" style="stop-color:#CF59C4"/>
	<stop  offset="1" style="stop-color:#8766D1"/>
</linearGradient>
<ellipse class="st1" cx="1663.8" cy="509.4" rx="482.4" ry="494.8"/>
<linearGradient id="SVGID_00000081616413124625002370000014684055843781458562_" gradientUnits="userSpaceOnUse" x1="2540.4585" y1="6886.1084" x2="3517.3789" y2="5194.0327" gradientTransform="matrix(0.9219 -0.3874 -0.3874 -0.9219 402.835 7320.6885)">
	<stop  offset="0.2925" style="stop-color:#121827"/>
	<stop  offset="0.6183" style="stop-color:#67356D"/>
	<stop  offset="1" style="stop-color:#CF59C4"/>
</linearGradient>
<path style="fill:url(#SVGID_00000081616413124625002370000014684055843781458562_);fill-opacity:0.9;" d="M1628.2,841.9
	c-93.9-229.9-242.1-419.1-422.3-558.3C868.3,23,418-62.2,0,89.9v401.3c22.4-12,45.5-23.1,69.2-33.4
	c211.6-91.5,386.6-96.2,533.5-29.9c184.6,83.3,324.9,278.8,437.8,555.3c13.1,32,24.4,64.2,33.9,96.7h627.1
	C1684.5,999.9,1660.2,920.2,1628.2,841.9z"/>
<linearGradient id="SVGID_00000106829923085504476910000015846322623668807586_" gradientUnits="userSpaceOnUse" x1="-329.6132" y1="3324.8501" x2="2270.915" y2="3324.8501" gradientTransform="matrix(1 0 0 -1 0 4006)">
	<stop  offset="0" style="stop-color:#CF59C4"/>
	<stop  offset="1.373630e-03" style="stop-color:#CF59C4"/>
	<stop  offset="1" style="stop-color:#8766D1"/>
</linearGradient>
<path style="opacity:0.15;fill:url(#SVGID_00000106829923085504476910000015846322623668807586_);enable-background:new    ;" d="
	M2020.8,941.5c0,47.5-6.7,93.9-19.3,138.6H297.1c-12.6-44.7-19.3-91-19.3-138.6c0-364.1,390.2-659.3,871.4-659.3
	C1630.6,282.2,2020.8,577.4,2020.8,941.5z"/>
<linearGradient id="SVGID_00000153681675744015696780000014927556909651820197_" gradientUnits="userSpaceOnUse" x1="-338.0803" y1="3518.6001" x2="1133.3571" y2="3518.6001" gradientTransform="matrix(1 0 0 -1 0 4006)">
	<stop  offset="0" style="stop-color:#CF59C4"/>
	<stop  offset="1.373630e-03" style="stop-color:#CF59C4"/>
	<stop  offset="1" style="stop-color:#8766D1"/>
</linearGradient>
<ellipse style="opacity:0.15;fill:url(#SVGID_00000153681675744015696780000014927556909651820197_);enable-background:new    ;" cx="498.7" cy="487.4" rx="493.1" ry="472.8"/>
<linearGradient id="SVGID_00000056416583566883014010000004740049144977727619_" gradientUnits="userSpaceOnUse" x1="779.3915" y1="3487.3867" x2="1442.9862" y2="3161.1697" gradientTransform="matrix(0.999 7.106157e-03 -7.106157e-03 -0.9499 9.8093 3936.438)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<path style="fill:url(#SVGID_00000056416583566883014010000004740049144977727619_);" d="M1429,822.5
	c-23.9,168.6-207.6,281.9-410.2,253.2S671.4,887,695.3,718.4c23.9-168.6,207.6-281.9,410.2-253.2
	C1308.1,494,1452.9,653.9,1429,822.5z"/>
<linearGradient id="SVGID_00000018939800721905052010000006360783738856681398_" gradientUnits="userSpaceOnUse" x1="828.0363" y1="2616.8665" x2="336.0693" y2="2124.8994" gradientTransform="matrix(0.9782 0.1388 0.1321 -0.9306 187.0534 2868.8469)">
	<stop  offset="0" style="stop-color:#FF5454"/>
	<stop  offset="1.373630e-03" style="stop-color:#EB9B22"/>
	<stop  offset="0.8751" style="stop-color:#FF5454"/>
</linearGradient>
<path style="fill:url(#SVGID_00000018939800721905052010000006360783738856681398_);" d="M1428.2,794.2
	c-23.9,168.6-203.9,282.4-402,254.3C828.2,1020.4,687,861,711,692.4c23.9-168.6,203.9-282.4,402-254.3S1452.2,625.6,1428.2,794.2z"
	/>
<linearGradient id="SVGID_00000176007912018460977160000000328150391653964203_" gradientUnits="userSpaceOnUse" x1="1601.4796" y1="3434.9641" x2="1814.9713" y2="3209.675" gradientTransform="matrix(1.0178 -3.450000e-02 -3.290000e-02 -0.9696 -161.4972 4317.9482)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<path style="fill:url(#SVGID_00000176007912018460977160000000328150391653964203_);" d="M1661.8,1080.1
	c-1.4-52.2-25.9-107.5-72.1-151c-84.6-79.7-209.8-89.7-279.7-22.4c-45.8,44.1-56.4,110.5-34.5,173.4h71
	c-19.6-44.2-13.7-93,19.3-124.2c45.8-43.2,126.4-36.5,180.1,15.1c32.7,31.4,47.9,72.2,44.4,109.1H1661.8z"/>
<linearGradient id="SVGID_00000031168277381160412600000001679255981956756395_" gradientUnits="userSpaceOnUse" x1="1700.4045" y1="3349.8523" x2="1487.0966" y2="3107.2961" gradientTransform="matrix(0.9994 -3.390000e-02 -3.390000e-02 -0.9994 -8.2889 4296.5986)">
	<stop  offset="0" style="stop-color:#BF8AF0"/>
	<stop  offset="0.6325" style="stop-color:#CF59C4"/>
</linearGradient>
<path style="fill:url(#SVGID_00000031168277381160412600000001679255981956756395_);" d="M1602.7,913.2
	c-83.2-82.3-206.2-92.8-274.7-23.5c-47.6,48.1-56.3,122.1-29.3,190.4h71c-25.6-48.4-22-104.7,13.3-139.6
	c44.9-44.5,124.1-37.4,176.9,15.9c35.4,35.7,49.9,83,42.2,123.7h71.8C1675.6,1023.2,1651.3,961.3,1602.7,913.2z"/>
<linearGradient id="blue_00000047738856542769842390000004852428855370467751_" gradientUnits="userSpaceOnUse" x1="1654.7001" y1="2766.2" x2="1902.1" y2="2766.2" gradientTransform="matrix(1 0 0 -1 0 2958)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<circle id="blue_00000111903040733330695070000012128824647269697725_" style="opacity:0.7;fill:url(#blue_00000047738856542769842390000004852428855370467751_);enable-background:new    ;" cx="1778.4" cy="191.8" r="123.7"/>
<linearGradient id="blue_00000031897940491971044420000003367119937464332677_" gradientUnits="userSpaceOnUse" x1="1392.1001" y1="2619.8" x2="1639.5" y2="2619.8" gradientTransform="matrix(1 0 0 -1 0 2958)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<circle id="blue_00000086653733844587061050000000208483013182727846_" style="opacity:0.7;fill:url(#blue_00000031897940491971044420000003367119937464332677_);enable-background:new    ;" cx="1515.8" cy="338.2" r="123.7"/>
<linearGradient id="SVGID_00000002373130588218324670000001635714428536535967_" gradientUnits="userSpaceOnUse" x1="1644.2001" y1="2416" x2="1891.6" y2="2416" gradientTransform="matrix(1 0 0 -1 0 2958)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<circle style="opacity:0.7;fill:url(#SVGID_00000002373130588218324670000001635714428536535967_);enable-background:new    ;" cx="1767.9" cy="542" r="123.7"/>
<linearGradient id="SVGID_00000118387460524995312430000004949621929774740659_" gradientUnits="userSpaceOnUse" x1="333.2" y1="2506.7" x2="580.6" y2="2506.7" gradientTransform="matrix(1 0 0 -1 0 2958)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<circle style="opacity:0.7;fill:url(#SVGID_00000118387460524995312430000004949621929774740659_);enable-background:new    ;" cx="456.9" cy="451.3" r="123.7"/>
<linearGradient id="blue_00000012465830995225367030000016626800359276595863_" gradientUnits="userSpaceOnUse" x1="1896.4" y1="2583.5" x2="2143.8" y2="2583.5" gradientTransform="matrix(1 0 0 -1 0 2958)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<ellipse id="blue_00000052095164568655869750000013142419481789363101_" style="opacity:0.7;fill:url(#blue_00000012465830995225367030000016626800359276595863_);enable-background:new    ;" cx="2020.1" cy="374.5" rx="123.7" ry="123.7"/>
<linearGradient id="SVGID_00000029757748838013312910000015984376881423694512_" gradientUnits="userSpaceOnUse" x1="1863.4" y1="2205.8" x2="2110.8" y2="2205.8" gradientTransform="matrix(1 0 0 -1 0 2958)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<circle style="opacity:0.7;fill:url(#SVGID_00000029757748838013312910000015984376881423694512_);enable-background:new    ;" cx="1987.1" cy="752.2" r="123.7"/>
<g class="st15">

		<linearGradient id="SVGID_00000001654120968549853190000018299580305911631503_" gradientUnits="userSpaceOnUse" x1="1362.9" y1="2285" x2="1610.2999" y2="2285" gradientTransform="matrix(1 0 0 -1 0 2958)">
		<stop  offset="0" style="stop-color:#3B3555"/>
		<stop  offset="1" style="stop-color:#3D3F5D"/>
	</linearGradient>
	<circle style="fill:url(#SVGID_00000001654120968549853190000018299580305911631503_);" cx="1486.6" cy="673" r="123.7"/>
</g>
<linearGradient id="SVGID_00000036215759030027086480000008649156837070131585_" gradientUnits="userSpaceOnUse" x1="506.3" y1="2242.8" x2="753.7" y2="2242.8" gradientTransform="matrix(1 0 0 -1 0 2958)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<circle style="opacity:0.7;fill:url(#SVGID_00000036215759030027086480000008649156837070131585_);enable-background:new    ;" cx="630" cy="715.2" r="123.7"/>
<linearGradient id="SVGID_00000050638151864955973580000010958832920833701014_" gradientUnits="userSpaceOnUse" x1="395.8" y1="2803.8" x2="643.2" y2="2803.8" gradientTransform="matrix(1 0 0 -1 0 2958)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<circle style="opacity:0.7;fill:url(#SVGID_00000050638151864955973580000010958832920833701014_);enable-background:new    ;" cx="519.5" cy="154.2" r="123.7"/>
<linearGradient id="SVGID_00000021078837294591197770000005004411015028670892_" gradientUnits="userSpaceOnUse" x1="84.4" y1="2707.3" x2="331.8" y2="2707.3" gradientTransform="matrix(1 0 0 -1 0 2958)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<circle style="opacity:0.7;fill:url(#SVGID_00000021078837294591197770000005004411015028670892_);enable-background:new    ;" cx="208.1" cy="250.7" r="123.7"/>
<linearGradient id="circleSunmi_00000105390039547911497360000015233584949973731499_" gradientUnits="userSpaceOnUse" x1="49.4" y1="2369.3" x2="296.8" y2="2369.3" gradientTransform="matrix(1 0 0 -1 0 2958)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<circle id="circleSunmi_00000124854734780473781750000008226024988773760646_" style="opacity:0.7;fill:url(#circleSunmi_00000105390039547911497360000015233584949973731499_);enable-background:new    ;" cx="173.1" cy="588.7" r="123.7"/>
<linearGradient id="red_00000093882331663819273850000018084134821335215781_" gradientUnits="userSpaceOnUse" x1="1073.3" y1="2762.6001" x2="1320.7" y2="2762.6001" gradientTransform="matrix(1 0 0 -1 0 2958)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<circle id="red_00000077282192200598813470000000200993941994483854_" style="opacity:0.7;fill:url(#red_00000093882331663819273850000018084134821335215781_);enable-background:new    ;" cx="1197" cy="195.4" r="123.7"/>
<linearGradient id="SVGID_00000098918024620264156870000014478667545873035679_" gradientUnits="userSpaceOnUse" x1="960.2" y1="2494" x2="1207.6" y2="2494" gradientTransform="matrix(1 0 0 -1 0 2958)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<circle style="opacity:0.7;fill:url(#SVGID_00000098918024620264156870000014478667545873035679_);enable-background:new    ;" cx="1083.9" cy="464" r="123.7"/>
<linearGradient id="SVGID_00000011732001451621039840000015608082626393955972_" gradientUnits="userSpaceOnUse" x1="714" y1="2682.7" x2="961.4" y2="2682.7" gradientTransform="matrix(1 0 0 -1 0 2958)">
	<stop  offset="0" style="stop-color:#3B3555"/>
	<stop  offset="1" style="stop-color:#3D3F5D"/>
</linearGradient>
<circle style="opacity:0.7;fill:url(#SVGID_00000011732001451621039840000015608082626393955972_);enable-background:new    ;" cx="837.7" cy="275.3" r="123.7"/>
<circle id="blue_00000096741361586316532450000017917117551919453335_" class="st24" cx="1773.5" cy="187.9" r="123.7"/>
<circle id="blue" class="st24" cx="1510.9" cy="334.3" r="123.7"/>
<circle id="red" class="st25" cx="1194" cy="191.3" r="123.7"/>
<circle class="st24" cx="1763" cy="538" r="123.7"/>
<circle class="st26" cx="452" cy="447.4" r="123.7"/>
<ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.1672 900.1454)" class="st27" cx="1082.5" cy="459.9" rx="123.7" ry="123.7"/>
<g id="MultiTenancy">

		<image style="overflow:visible;opacity:0.5;" width="187" height="159" xlink:href="images/A47D36C4.png"  transform="matrix(1 0 0 1 1000 361)">
	</image>
	<g>
		<path class="st28" d="M1026.9,393.1v5.1c0,3.5,2.3,6.3,5.2,6.3h108.6c2.9,0,5.2-2.8,5.2-6.3v-5.1"/>
		<line class="st28" x1="1086.8" y1="393.1" x2="1086.8" y2="413.9"/>
		<path class="st28" d="M1026.9,465.2V460c0-3.5,2.3-6.3,5.2-6.3h108.6c2.9,0,5.2,2.8,5.2,6.3v5.1"/>
		<line class="st28" x1="1086.8" y1="465.2" x2="1086.8" y2="444.3"/>
		<g>
			<circle class="st29" cx="1139.1" cy="374.6" r="5.1"/>
			<path class="st29" d="M1127.4,393c0,0,0-1.7,0-2.9c0-2,0-1.5,0-1.6c0.2-2.2,5.6-5.5,11.5-5.5c6.1,0,11.7,2.9,11.9,5.2
				c0,0.3,0.1,1.6,0.1,2c0,1.7,0,2.8,0,2.8H1127.4z"/>
			<circle class="st29" cx="1152.7" cy="374.6" r="5.1"/>
			<path class="st29" d="M1154.4,393c0,0,0-1.7,0-2.9c0-2,0-1.5,0-1.6c-0.1-3.1-3.3-5.4-3.3-5.4s1-0.1,1.4-0.1
				c6.1,0,11.7,2.9,11.9,5.2c0,0.3,0.1,1.6,0.1,2c0,1.7,0,2.8,0,2.8H1154.4z"/>
		</g>
		<g>
			<circle class="st30" cx="1080" cy="374.6" r="5.1"/>
			<path class="st30" d="M1068.3,393c0,0,0-1.7,0-2.9c0-2,0-1.5,0-1.6c0.2-2.2,5.6-5.5,11.5-5.5c6.1,0,11.7,2.9,11.9,5.2
				c0,0.3,0.1,1.6,0.1,2c0,1.7,0,2.8,0,2.8H1068.3z"/>
			<circle class="st30" cx="1093.6" cy="374.6" r="5.1"/>
			<path class="st30" d="M1095.3,393c0,0,0-1.7,0-2.9c0-2,0-1.5,0-1.6c-0.1-3.1-3.3-5.4-3.3-5.4s1-0.1,1.4-0.1
				c6.1,0,11.7,2.9,11.9,5.2c0,0.3,0.1,1.6,0.1,2c0,1.7,0,2.8,0,2.8H1095.3z"/>
		</g>
		<g>

				<ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 33.9746 831.1913)" class="st31" cx="1020.3" cy="374.6" rx="5.1" ry="5.1"/>
			<path class="st31" d="M1008.6,393c0,0,0-1.7,0-2.9c0-2,0-1.5,0-1.6c0.2-2.2,5.6-5.5,11.5-5.5c6.1,0,11.7,2.9,11.9,5.2
				c0,0.3,0.1,1.6,0.1,2c0,1.7,0,2.8,0,2.8H1008.6z"/>
			<circle class="st31" cx="1033.9" cy="374.6" r="5.1"/>
			<path class="st31" d="M1035.5,393c0,0,0-1.7,0-2.9c0-2,0-1.5,0-1.6c-0.1-3.1-3.3-5.4-3.3-5.4s1-0.1,1.4-0.1
				c6.1,0,11.7,2.9,11.9,5.2c0,0.3,0.1,1.6,0.1,2c0,1.7,0,2.8,0,2.8H1035.5z"/>
		</g>
		<g>
			<ellipse class="st31" cx="1026.9" cy="470.3" rx="14" ry="6.1"/>
			<path class="st31" d="M1040.9,477.6c0,0-1.6,5.3-14,5.3c-11.3,0-14-4.9-14-4.9v-4.7c0,0,3.1,4.4,14,4.4c10.6,0,13.8-4.2,13.8-4.2
				L1040.9,477.6z"/>
			<path class="st31" d="M1040.9,484.3c0,0-1.6,5.3-14,5.3c-11.3,0-14-4.9-14-4.9V480c0,0,3.1,4.4,14,4.4c10.6,0,13.8-4.2,13.8-4.2
				L1040.9,484.3z"/>
			<path class="st31" d="M1040.9,491c0,0-1.6,5.3-14,5.3c-11.3,0-14-4.9-14-4.9v-4.7c0,0,3.1,4.4,14,4.4c10.6,0,13.8-4.2,13.8-4.2
				L1040.9,491z"/>
		</g>
		<g>
			<ellipse class="st30" cx="1083.2" cy="470" rx="14" ry="6.1"/>
			<path class="st30" d="M1097.1,477.3c0,0-1.6,5.3-14,5.3c-11.3,0-14-4.9-14-4.9V473c0,0,3.1,4.4,14,4.4c10.6,0,13.8-4.2,13.8-4.2
				L1097.1,477.3z"/>
			<path class="st30" d="M1097.1,484c0,0-1.6,5.3-14,5.3c-11.3,0-14-4.9-14-4.9v-4.7c0,0,3.1,4.4,14,4.4c10.6,0,13.8-4.2,13.8-4.2
				L1097.1,484z"/>
			<path class="st30" d="M1097.1,490.7c0,0-1.6,5.3-14,5.3c-11.3,0-14-4.9-14-4.9v-4.7c0,0,3.1,4.4,14,4.4c10.6,0,13.8-4.2,13.8-4.2
				L1097.1,490.7z"/>
		</g>
		<g>
			<ellipse class="st29" cx="1146" cy="471.1" rx="14" ry="6.1"/>
			<path class="st29" d="M1160,478.4c0,0-1.6,5.3-14,5.3c-11.3,0-14-4.9-14-4.9v-4.7c0,0,3.1,4.4,14,4.4c10.6,0,13.8-4.2,13.8-4.2
				L1160,478.4z"/>
			<path class="st29" d="M1160,485.1c0,0-1.6,5.3-14,5.3c-11.3,0-14-4.9-14-4.9v-4.7c0,0,3.1,4.4,14,4.4c10.6,0,13.8-4.2,13.8-4.2
				L1160,485.1z"/>
			<path class="st29" d="M1160,491.8c0,0-1.6,5.3-14,5.3c-11.3,0-14-4.9-14-4.9v-4.7c0,0,3.1,4.4,14,4.4c10.6,0,13.8-4.2,13.8-4.2
				L1160,491.8z"/>
		</g>
		<polygon class="st31" points="1074.9,419.9 1086.6,426.7 1098.7,419.9 1087.2,413 		"/>
		<polygon class="st30" points="1074.4,422.5 1085.8,428.4 1085.7,440.4 1074.4,433.9 		"/>
		<polygon class="st29" points="1088.1,428.5 1088,440.4 1099.3,433.8 1099.3,421.7 		"/>
	</g>
</g>
<ellipse id="blue_00000115500762590768419400000013370709304422568101_" transform="matrix(0.7071 -0.7071 0.7071 0.7071 328.1852 1533.4945)" class="st24" cx="2015.2" cy="370.6" rx="123.7" ry="123.7"/>
<circle class="st24" cx="1982.2" cy="748.2" r="123.7"/>
<circle class="st32" cx="1481.7" cy="669.1" r="123.7"/>
<circle class="st33" cx="832.7" cy="270.2" r="123.7"/>
<circle class="st34" cx="625.1" cy="711.3" r="123.7"/>
<circle class="st35" cx="514.6" cy="150.3" r="123.7"/>
<circle class="st36" cx="203.2" cy="246.8" r="123.7"/>
<circle id="circleSunmi" class="st37" cx="168.2" cy="584.7" r="123.7"/>
<image style="overflow:visible;opacity:0.5;enable-background:new    ;" width="166" height="122" xlink:href="images/A34A860C.png"  transform="matrix(1 0 0 1 1942 286)">
</image>
<path class="st31" d="M1968.9,377.4c-2,0.4-2.8-0.7-1.8-2.6c3-5.4,4.1-10.9,3.2-16.3c-11.4-6.6-19.2-16.2-19.2-28.1
	c-0.2-17,15.5-28.6,31.2-32.5c15.6-4.3,32.3-2,46.8,6.4c14.5,7.6,21.2,27.6,11.6,41.3c-8.8,13.3-27.6,19.7-42.6,19.7
	c-2,0-5.2-0.2-9.5-0.7C1984.8,371.2,1978.2,375.5,1968.9,377.4z M2072,386c-7.4-1.7-12.7-5.3-16-10.7c-14.5,1.9-28.9-1.4-39-11
	c8.8-2.8,16-7.1,21.8-13c5.8-5.8,8.6-12.7,8.6-21c0-4-0.7-7.7-2.2-11c8.2-0.6,18,1.6,26.6,5.9c8.5,4.4,14.8,12.7,14.8,22.3
	c0,9.7-6.6,17.6-15.8,22.9c-0.6,4.7,0.2,9.1,2.6,13.6C2074.1,385.3,2073.4,386.2,2072,386z"/>
<image style="overflow:visible;opacity:0.5;enable-background:new    ;" width="157" height="137" xlink:href="images/A34A8601.png"  transform="matrix(1 0 0 1 1119 107)">
</image>
<path class="st31" d="M1188.8,196.2h-41.9c-5.1,0-9.5-1.8-13.1-5.5s-5.4-8-5.4-13v-43.2c0-5.1,1.8-9.5,5.4-13.1s8-5.4,13.1-5.4h89.5
	c5.1,0,9.5,1.8,13.1,5.4s5.5,8,5.5,13.1v43.2c0,5-1.8,9.4-5.5,13s-8,5.5-13.1,5.5h-41.9v20.6h25.9c1,0,1.6,0.5,1.6,1.6v2.6
	c0,1-0.5,1.6-1.6,1.6h-57.6c-1,0-1.6-0.5-1.6-1.6v-2.6c0-1,0.5-1.6,1.6-1.6h25.9L1188.8,196.2L1188.8,196.2z"/>
<image style="overflow:visible;opacity:0.5;enable-background:new    ;" width="155" height="142" xlink:href="images/A34A8600.png"  transform="matrix(1 0 0 1 751 175)">
</image>
<path class="st31" d="M880.4,213.4c2.6,0,4.4,2.2,3.8,4.7l-10.7,39.3c-2.2,8.5-10.1,14.2-19,14.2H823c-9.9,0-17.5-5.9-19.2-15.3
	l-11.4-58c-1.6-7.7-6-10.8-13.9-10.8H761c-0.4,0-0.7-0.3-0.7-0.8v-1c0-0.5,0.3-0.8,0.7-0.8h17.5c9.2,0,14.7,4.3,16.4,12.9l3,15.7
	L880.4,213.4L880.4,213.4z M827.6,283c2.9,2.9,2.9,7.4,0,10.1s-7.4,2.7-10.3,0c-2.9-2.7-2.9-7.3,0-10.1
	C820.2,280.2,824.8,280.2,827.6,283z M862.3,288.1c0,3.8-3.4,7.1-7.2,7.1c-6.4,0-9.8-7.7-5.1-12.2
	C854.7,278.4,862.3,281.7,862.3,288.1z"/>
<image style="overflow:visible;opacity:0.5;enable-background:new    ;" width="129" height="137" xlink:href="images/A34A861D.png"  transform="matrix(1 0 0 1 1708 446)">
</image>
<path class="st31" d="M1717.1,505.2c0-3.7,1.3-7,3.8-9.7l30.5,30.6l-30.7,31c-2.4-2.6-3.6-5.8-3.6-9.5L1717.1,505.2L1717.1,505.2z
	 M1757.1,523.6c2.4-2.4,5.4-3.6,9.1-3.6c3.6,0,6.6,1.2,9,3.6l34.9,34.9c-2.5,2-5.5,3.1-9,3.1h-69.8c-3.4,0-6.4-1.1-8.9-3.1
	L1757.1,523.6z M1779.4,524.6l-2.6-2.6c-2.8-2.8-6.4-4.2-10.6-4.2c-4.3,0-7.9,1.4-10.7,4.3l-2.5,2.4l-30.4-30.7l34.7-34.9
	c4.8-4.9,13-4.9,17.9-0.1l34.7,35L1779.4,524.6z M1780.9,526.2l30.5-30.7c2.6,2.8,4,6,4,9.7v42.4c0,3.6-1.2,6.7-3.7,9.5
	L1780.9,526.2z"/>
<image style="overflow:visible;opacity:0.5;enable-background:new    ;" width="120" height="114" xlink:href="images/A34A861F.png"  transform="matrix(1 0 0 1 1457 268)">
</image>
<path class="st31" d="M1540.3,277.4c4.2,0,7.9,1.5,10.9,4.6s4.6,6.7,4.6,10.9v36c0,4.2-1.5,7.9-4.6,10.9s-6.7,4.6-10.9,4.6h-35.4
	l-16.4,15.4c-1.4,1.2-2.8,1.5-4.3,0.8s-2.2-1.9-2.2-3.7v-12.1c-4.7-0.6-8.6-2.2-11.5-4.9s-4.4-6.3-4.4-11v-36
	c0-4.2,1.5-7.9,4.6-10.9s6.7-4.6,10.9-4.6L1540.3,277.4L1540.3,277.4z M1536.9,297.9c1,0,1.6-0.5,1.6-1.6v-2.5c0-1-0.5-1.6-1.6-1.6
	h-52.2c-1,0-1.6,0.5-1.6,1.6v2.5c0,1,0.5,1.6,1.6,1.6H1536.9z M1536.9,313.7c1,0,1.6-0.5,1.6-1.6v-2.6c0-1-0.5-1.6-1.6-1.6h-52.2
	c-1,0-1.6,0.5-1.6,1.6v2.6c0,1,0.5,1.6,1.6,1.6H1536.9z M1519.2,329.5c1,0,1.6-0.5,1.6-1.6v-2.5c0-1-0.5-1.6-1.6-1.6h-34.4
	c-1,0-1.6,0.5-1.6,1.6v2.5c0,1,0.5,1.6,1.6,1.6H1519.2z"/>
<image style="overflow:visible;opacity:0.5;enable-background:new    ;" width="145" height="146" xlink:href="images/A34A861C.png"  transform="matrix(1 0 0 1 1421 573)">
</image>
<path class="st31" d="M1474.3,590.3c7.8-7.8,16.6-9.6,26.8-5.8c14.8,5.8,26.1,11.4,33.7,17.2c7.5,5.8,10.7,11,10,15.6
	c-0.7,6.2-9.6,9.6-19,10.6c-4.7,0.6-9.9,1.7-15.4,3.4s-9.9,4.1-12.8,7L1486,650c-3,3-5.4,7.2-7.1,12.7s-2.8,10.7-3.2,15.4
	c-1.1,9.4-4.5,18.2-10.7,19.2c-4.7,0.6-9.9-2.8-15.6-10.2c-5.8-7.5-11.4-18.6-17.1-33.5c-3.9-10.3-2.1-19.2,5.6-26.9L1474.3,590.3z
	 M1456.6,643.1l-8,8c-0.4,0.4-0.4,0.7,0,1.1l0.8,0.8c0.4,0.1,0.7,0.1,1,0l8.2-8.2l8,8.2c0.4,0.3,0.7,0.3,1,0l1-0.8
	c0.3-0.4,0.3-0.7,0-1.1l-8.2-8l8.2-8.2c0.3-0.3,0.3-0.6,0-1l-1-0.8c-0.3-0.4-0.6-0.4-1,0l-8,8l-8.2-8c-0.3-0.3-0.6-0.3-1,0l-0.8,0.8
	c-0.4,0.3-0.4,0.6,0,1L1456.6,643.1z M1479.7,608c2.1,2.1,5.5,2.1,7.6,0s2.1-5.5,0-7.6s-5.5-2.1-7.6,0S1477.6,605.9,1479.7,608z
	 M1479.7,623.1c2.1,2.1,5.5,2.1,7.6,0s2.1-5.5,0-7.6s-5.5-2.1-7.6,0S1477.6,621,1479.7,623.1z M1495.5,608c2.1,2.1,5.5,2.1,7.6,0
	s2.1-5.5,0-7.6s-5.5-2.1-7.6,0S1493.4,605.9,1495.5,608z M1495.5,623.1c2.1,2.1,5.5,2.1,7.6,0s2.1-5.5,0-7.6s-5.5-2.1-7.6,0
	S1493.4,621,1495.5,623.1z"/>
<image style="overflow:visible;opacity:0.5;enable-background:new    ;" width="97" height="129" xlink:href="images/A34A8630.png"  transform="matrix(1 0 0 1 1730 78)">
</image>
<path class="st31" d="M1741.9,170.9c-2.6,0-3.2-1.2-2-3.6c5.4-10.2,8.2-21.8,8.2-34.8c0-4.8,0.9-9.1,2.6-12.8s4.1-6.6,7-8.5
	c2.9-2,6-3.4,9.5-4.3c3.4-0.9,6.9-0.9,10.3,0s6.5,2.3,9.4,4.3s5.2,4.8,7,8.5s2.6,8,2.6,12.8c0,13,2.7,24.6,8.2,34.8
	c1.2,2.4,0.5,3.6-2,3.6H1741.9z M1784.2,176.6c-0.6,2.9-2.2,5.1-4.6,6.7s-4.9,2.4-7.5,2.4s-5.1-0.8-7.5-2.4
	c-2.4-1.6-3.9-3.8-4.6-6.7H1784.2z M1766.9,89.8c1.5-1.5,3.3-2.3,5.3-2.3s3.9,0.8,5.3,2.3s2.2,3.3,2.2,5.4c0,2-0.7,3.8-2.2,5.3
	s-3.3,2.3-5.3,2.3s-3.9-0.7-5.3-2.2s-2.2-3.3-2.2-5.3C1764.7,93.1,1765.4,91.3,1766.9,89.8z"/>
<image style="overflow:visible;opacity:0.5;enable-background:new    ;" width="138" height="144" xlink:href="images/A34A8631.png"  transform="matrix(1 0 0 1 389 356)">
</image>
<path class="st31" d="M448.1,367c2.7-1.3,5.2-1.4,7.8-0.1l47.5,22.8c2.9,1.5,2.9,5.6,0,6.9l-46.3,22.3c-3.4,1.5-6.7,1.5-10.1,0
	l-46.3-22.3c-2.9-1.5-2.9-5.3,0-6.9L448.1,367z M503.4,419c2.9,1.5,2.9,5.6,0,6.9l-46.3,22.3c-3.4,1.5-6.7,1.5-10.1,0l-46.3-22.3
	c-2.9-1.3-2.9-5.3,0-6.9l20.2-9.7L447,422c3.4,1.5,6.7,1.5,10.1,0l26.2-12.6L503.4,419z M503.4,448.3c2.9,1.5,2.9,5.6,0,6.9
	l-46.3,22.3c-3.4,1.5-6.7,1.5-10.1,0l-46.3-22.3c-2.9-1.5-2.9-5.6,0-6.9l20.2-9.7l26.2,12.6c3.4,1.5,6.7,1.5,10.1,0l26.2-12.6
	L503.4,448.3z"/>
<image style="overflow:visible;opacity:0.5;enable-background:new    ;" width="179" height="176" xlink:href="images/A34A861E.png"  transform="matrix(1 0 0 1 538 603)">
</image>
<path class="st31" d="M548.5,707.7c-1.4-1.4-1.8-2.9-1-4.6c3.8-10.7,10.1-20,15.2-25.8c2.4-2.7,5.9-5.8,10.2-9
	c4.3-3,7.8-5.4,10.2-6.9c2.4-1.4,6.2-3.5,11.4-6.4c4.3-2.4,7.7-4.3,10.1-5.8c2.4-1.3,5.4-3.2,9.4-5.9c3.8-2.7,7-5.3,9.4-7.7
	c5-4.8,11-12.3,15-20.6c1.4-2.7,5-3.7,7.4-1.3l48.2,48.6c1.6,1.4,1.9,3,1,5.1c-4.3,9.1-10.6,17.6-15.7,22.7
	c-2.7,2.7-5.9,5.4-9.9,8.2c-4,2.9-7.4,5-9.9,6.4s-6.1,3.5-10.7,6.1c-5.9,3.2-10.4,5.8-13.4,7.7s-6.9,4.6-11.5,8.3
	c-9.1,7.2-15.4,16-19.8,27.7c-1.1,3-5.3,4-7.4,1.6L548.5,707.7z M599.2,663.7c-0.3,0.3-0.3,0.6,0,1.1l3.2,3.2
	c-7.2,5.8-8,11.5-2.1,17.1c4.3,4.3,10.1,4,13.4,2.6c1.8-0.8,4-1.9,6.9-3.7c5.3-3.2,6.9-4.3,11-4.3c2.1,0.2,3.8,1,5.4,2.7
	c4.6,4.5,3.5,9.3-3.4,14.6c-5,3.8-10.9,4.3-17.6,1.4c-0.5-0.2-1-0.2-1.3,0.3l-0.5,1c-0.5,0.5-0.3,1,0.3,1.3
	c7.4,3.2,13.9,2.9,19.5-1.1l3.4,3.4c0.3,0.3,0.6,0.3,1.1,0l1-0.8c0.3-0.2,0.3-0.5,0.2-1l-3.4-3.2c7.7-6.2,8.6-12.2,3-17.8
	c-4.3-4.3-10.4-4.3-13.8-2.7c-1.8,0.8-4,2.1-6.7,3.8c-5.1,3.2-6.9,4.3-10.9,4.2c-2.1,0-3.8-0.8-5.4-2.4c-4.6-4.6-3.8-9.3,2.2-13.9
	c5.8-4.5,11.2-4.6,17.1-2.1c0.5,0.2,0.8,0.2,1.1-0.3l0.6-1.1c0.3-0.5,0.2-0.8-0.5-1.1c-7.2-2.7-13.4-2.2-18.7,1.4l-3.2-3.2
	c-0.5-0.3-0.8-0.3-1.3,0L599.2,663.7z"/>
<image style="overflow:visible;opacity:0.5;enable-background:new    ;" width="148" height="141" xlink:href="images/99C55FB3.png"  transform="matrix(1 0 0 1 132 159)">
</image>
<path class="st31" d="M144.3,191c-1.8-1-2.7-2.2-2.7-3.6s0.9-2.6,2.7-3.6l25.7-14.1c2.4-1.3,4.8-1.3,7.2,0l15.1,8.1l-36.2,19.8
	L144.3,191z M192.3,226.4l-14.8,8c-1.7,0.8-3.4,0.8-5.1,0l-27.6-15.1c-1.9-1.1-2.9-2.3-2.9-3.6s0.9-2.5,2.9-3.6l11.3-6.2
	L192.3,226.4z M157.6,260v-25.4l13.9,7.6c2.1,1.1,4.2,1.1,6.3,0l18.2-9.9V279L157.6,260z M236.8,202l-37.2,20.4L163.5,202l36.3-19.9
	L236.8,202z M220.7,242.6c2.1,1.1,4.2,1.1,6.4,0l15-8.2v25.1l-39,19.5v-46.5L220.7,242.6z M244.3,206l10.8,5.9
	c1.9,1.1,2.9,2.3,2.9,3.6s-1,2.5-2.9,3.6l-28.4,15.5c-1.7,0.9-3.4,0.9-5.1-0.1l-14.7-8L244.3,206z M221.3,170.4
	c2.4-1.3,4.8-1.3,7.1,0l26.9,14.5c1.9,1,2.9,2.2,2.9,3.5c0,1.4-1,2.6-2.9,3.5l-11,5.9l-37-19.8L221.3,170.4z"/>
<image style="overflow:visible;opacity:0.5;enable-background:new    ;" width="122" height="135" xlink:href="images/99C55FBC.png"  transform="matrix(1 0 0 1 1916 658)">
</image>
<path class="st31" d="M2004.4,760.9c-0.4,2.3-2.6,3.6-4.7,3l-25.3-7.9c-0.6-0.1-1.1,0-1.4,0.6l-8.6,13.2c-1.6,2.4-5.2,2-6.2-0.5
	l-9.5-20.9c-0.1-0.4-0.4-0.6-0.7-0.7l-20.2-6.2c-2.8-0.8-3.5-4.3-1.2-6.1l81.8-67c1.7-1.4,3.5-1.7,5.5-0.6c1.9,1.1,2.8,2.8,2.4,5
	L2004.4,760.9z M1950.5,743.6l2.4,0.7l37.9-45.6l-1.8-1.6L1950.5,743.6z M1973.3,750.7l2.2,0.7l28.2-58.9l-2-1L1973.3,750.7z"/>
<g>

		<image style="overflow:visible;opacity:0.5;" width="161" height="144" xlink:href="images/C618EAA1.png"  transform="matrix(1 0 0 1 439 56)">
	</image>
	<g>
		<path class="st31" d="M568.9,134c4.8,0,8.7,3.9,8.7,8.6v26.6c0,4.8-3.9,8.6-8.7,8.6h-19.8c-4.8,0-8.7-3.8-8.7-8.6v-26.6
			c0-4.7,3.9-8.6,8.7-8.6h8.5v-5.6c0-3.5-2.9-6.2-6.1-6.2h-37.7v11.7h8.7c4.8,0,8.6,3.9,8.6,8.7v26.5c0,4.8-3.8,8.6-8.6,8.6h-19.9
			c-4.7,0-8.6-3.8-8.6-8.6v-26.5c0-4.8,3.9-8.7,8.6-8.7h8.7v-11.7h-37.7c-3.2,0-6.1,2.7-6.1,6.2v5.6h8.5c4.8,0,8.7,3.9,8.7,8.6v26.6
			c0,4.8-3.9,8.6-8.7,8.6h-19.8c-4.8,0-8.7-3.8-8.7-8.6v-26.6c0-4.7,3.9-8.6,8.7-8.6h8.8v-5.6c0-4.8,3.9-8.7,8.6-8.7h37.7v-11.3
			h-8.7c-4.7,0-8.6-3.9-8.6-8.7V73.1c0-4.8,3.9-8.7,8.6-8.7h19.9c4.8,0,8.6,3.9,8.6,8.7v26.5c0,4.8-3.8,8.7-8.6,8.7h-8.7v11.3h37.7
			c4.8,0,8.6,3.9,8.6,8.7v5.6L568.9,134L568.9,134z"/>
	</g>
</g>
<text transform="matrix(1 0 0 1 889.8536 742.181)" class="st38 st39 st40">Do it all</text>
<text transform="matrix(1 0 0 1 860.3736 861.721)" class="st38 st39 st40">Platform</text>
<text transform="matrix(1 0 0 1 894.8536 737.181)" class="st31 st39 st40">Do it all</text>
<text transform="matrix(1 0 0 1 865.3736 856.721)" class="st31 st39 st40">Platform</text>
<text transform="matrix(1 0 0 1 1047.868 526.0563)" class="st31 st41 st42">Multi- </text>
<text transform="matrix(1 0 0 1 1030.868 558.5563)" class="st31 st41 st42">Tenancy</text>
<text transform="matrix(1 0 0 1 774.9324 325.5117)" class="st31 st41 st42">Products/</text>
<text transform="matrix(1 0 0 1 758.9624 354.1017)" class="st31 st41 st42">E-commerce</text>
<text transform="matrix(1 0 0 1 1164.6327 270.3665)" class="st31 st41 st42">CRM</text>
<text transform="matrix(1 0 0 1 121.5652 679.4751)" class="st31 st41 st42">SUNMI</text>
<text transform="matrix(1 0 0 1 1483.0194 410.8153)" class="st31 st41 st42">SMS</text>
<text transform="matrix(1 0 0 1 1722.9847 610.1194)" class="st31 st41 st42">E-Mail</text>
<text transform="matrix(1 0 0 1 1743.1373 225.0166)" class="st31 st41 st42">Push </text>
<text transform="matrix(1 0 0 1 1699.5273 253.6066)" class="st31 st41 st42">Notifications</text>
<text transform="matrix(1 0 0 1 1956.6854 433.5248)" class="st31 st41 st42">WhatsApp</text>
<text transform="matrix(1 0 0 1 1916.9187 818.7449)" class="st31 st41 st42">Telegram</text>
<text transform="matrix(1 0 0 1 1404.5928 733.5497)" class="st31 st41 st42">Gamification</text>
<text transform="matrix(1 0 0 1 152.727 318.3865)" class="st31 st41 st42">Content</text>
<text transform="matrix(1 0 0 1 405.1274 515.9179)" class="st31 st41 st42">Storage</text>
<text transform="matrix(1 0 0 1 589.7664 792.8472)" class="st31 st41 st42">Billing</text>
<text transform="matrix(1 0 0 1 451.1313 223.132)" class="st31 st41 st42">Workflows</text>
<image style="overflow:visible;enable-background:new    ;" width="997" height="996" xlink:href="images/99C55FBE.png"  transform="matrix(0.2028 0 0 0.2028 28.2292 470.1007)">
</image>
<path class="st43" d="M110,980.6c6.1,0,11,4.2,11.1,10c0.1,1.6-1.1,2.6-2.4,2.6c-1.2,0-2.3-0.9-2.4-2.4c-0.1-3.3-2.8-5.7-6.4-5.7
	c-3.3,0-5.8,2.3-5.8,5.5c0,3.5,2.3,5,7.2,6.8c5.2,1.9,9.9,4.1,9.9,10.7c0,5.7-4.5,10.3-10.7,10.3c-6.1,0-11.2-4.3-11.2-10.2
	c0-3.6,1.8-6.2,3.5-6.2c1.5,0,2.4,1,2.4,2.3c0,1.1-1,1.6-1,3.7c0,3.6,3,6,6.3,6c3.5,0,5.8-2.4,5.8-5.4c0-3.6-2.3-5.2-7.2-6.9
	c-5.4-1.9-9.9-4.3-9.9-10.7C99.3,985.1,103.9,980.6,110,980.6z"/>
<path class="st43" d="M134.8,1018.3c-6.1,0-11.2-4.9-11.2-11.2c0-6.5,5.2-11.3,11.4-11.3c6.7,0,11.4,5,11.4,11.8v8.3
	c0,1.4-1.1,2.4-2.4,2.4c-1.4,0-2.5-1-2.5-2.4v-8.1c0-4.5-2.6-7.6-6.6-7.6c-3.6,0-6.4,3-6.4,6.9c0,3.6,2.8,6.6,6.3,6.6
	c1.3,0,1.9-0.3,2.7-0.3c1.1,0,2.2,0.9,2.2,2.2C139.6,1017.4,137.5,1018.3,134.8,1018.3z"/>
<path class="st43" d="M160.3,1035.2c-6.4,0-11.6-4.5-11.6-10.4c0-2,1.1-2.9,2.4-2.9c1.3,0,2.4,0.9,2.4,2.5c0.1,3.7,3,6.4,6.7,6.4
	c3.9,0,6.5-2.5,6.5-5.7c0-3.7-2.8-5.2-7.7-6.8c-5.6-1.8-10.4-4.3-10.4-11.2c0-6.4,4.9-11.2,11.2-11.2c6.4,0,11.7,4.7,11.7,10.8
	c0,3.7-1.5,6.6-3.6,6.6c-1.4,0-2.4-1-2.4-2.3s1.1-1.9,1.1-4.2c0-3.9-3.1-6.5-6.8-6.5c-3.6,0-6.4,2.7-6.4,6.3c0,4,2.9,5.7,7.7,7.3
	c5.5,1.8,10.4,4.1,10.4,10.7C171.6,1030.6,166.9,1035.2,160.3,1035.2z"/>
<path class="st43" d="M185.6,1018.3c-6.4,0-11.6-5-11.6-11.2s5.1-11.2,11.3-11.2c6.3,0,11.1,4.4,11.1,9.9c0,2.2-1.5,3.5-4,3.5h-6.5
	c-1.3,0-2.3-0.9-2.3-2.1c0-1.1,1-2,2.3-2h4.9c0.5,0,0.8-0.2,0.8-0.7c0-1.6-2.1-4.2-6-4.2c-3.6,0-6.6,3.1-6.6,6.9
	c0,3.8,3,6.8,6.8,6.8c4.3,0,5.7-2.6,7.5-2.6c1.5,0,2.3,1,2.3,2s-0.7,1.9-2.4,3C191.3,1017.4,188.7,1018.3,185.6,1018.3z"/>
<image style="overflow:visible;enable-background:new    ;" width="123" height="123" xlink:href="images/mySQL.png"  transform="matrix(1 0 0 1 1794.84 937.0838)">
</image>
<image style="overflow:visible;enable-background:new    ;" width="120" height="118" xlink:href="images/PostgreSQL.png"  transform="matrix(1 0 0 1 1943.7 929)">
</image>
<image style="overflow:visible;enable-background:new    ;" width="150" height="78" xlink:href="images/sage_x3.png"  transform="matrix(1 0 0 1 284.6352 960.4523)">
</image>
<image style="overflow:visible;enable-background:new    ;" width="166" height="66" xlink:href="images/sagePastel.png"  transform="matrix(1 0 0 1 498.4885 971.2)">
</image>
</svg>




            </div>
          </div>
        </div>

        <!-- Products section -->
        <div class="relative bg-gradient-to-r from-red-600 to-amber-500 py-16 sm:py-24 lg:py-32" id="products" >
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 class="text-base  tracking-wider text-gray-200 uppercase" ref="productsBlock">APPLICATION DEVELOPMENT</h2>
              <p class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase drop-shadow-md text-gray-200 inline-block " >
                Our Products
              </p>
            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl" id="gsapDev" >
              <div v-for="product in products" :key="product.name" class="transform flex flex-col rounded-lg shadow-lg overflow-hidden bg-white border-2 border-yellow-500 hover:border-yellow-400" >

                <router-link :to="product.href" class="transform transition duration-200 hover:scale-105">
                  <div class="flex-shrink-0 bg-yellow-50">
                    <img class="h-48 w-full object-cover" :src="product.imageUrl" alt="" />
                  </div>
                  <div class="flex-1 p-6 flex flex-col justify-between border-t-2 border-yellow-300">
                    <div class="flex-1">
                        <p class="text-xl font-semibold text-gray-900">
                          {{ product.name }}
                        </p>
                        <p class="mt-3 text-base text-gray-500">
                          {{ product.description }}
                        </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <!-- Specialised Development section -->
        <div class="relative bg-gray-900 py-16 sm:py-24 lg:py-32"  >
          <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
            <h2 class="text-base tracking-wider text-gray-200 uppercase">BUILT TO ORDER</h2>
            <p class="text-4xl font-extrabold  tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
              Specialised Software Development
            </p>
            <!--<p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
              We’re proud to be your partner – Welcome to the DevInspire family.
            </p>-->
            <div class="mt-12" >
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                <div v-for="item in SpecialisedDev" :key="item.name" class="pt-6" id="gsapSpDev"> <!--:id="item.id"-->
                  <div class="flow-root bg-gray-100 rounded-lg px-6 pb-8">
                    <div class="-mt-6">
                      <div>
                        <span class="inline-flex items-center justify-center p-3 bg-gradient-to-r from-red-500 to-amber-600 rounded-md shadow-lg">
                          <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">{{ item.name }}</h3>
                      <p class="mt-5 text-base text-gray-500">
                        {{ item.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

  <!-- Companies -->
    <div class="bg-gray-700">
    <div class="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8 pb-16 pt-16 ">
      <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <p class="text-4xl font-extrabold  tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
			&nbsp;Partners and Customers&nbsp;
        </p>
      </div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6  lg:px-8">
        <div class="grid grid-cols-2 gap-2 md:grid-cols-4 lg:mt-8 ">
          <div v-for="company in companies" :key="company.name">
            <a :href="company.href">
            <div class="col-span-1 flex justify-center py-8 px-8 border-2 bg-white border-gray-100 hover:border-yellow-300 }">
              <img class="max-h-12" :src="company.imageUrl" :alt="company.name" />
            </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>

    <!-- Testimonials section -->
    <div class="relative bg-gray-900 py-16 sm:py-24 lg:py-32" id="testimonials">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
				<p class="text-4xl font-extrabold  tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">&nbsp;Testimonials&nbsp;</p>
				<p class="mx-auto max-w-prose text-lg text-gray-200">Our customers believe in us</p>
            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-2 lg:max-w-7xl" id="gsapDev">
              <div v-for="testimonial in testimonials" :key="testimonial.name" class="transform flex flex-col rounded-lg shadow-lg overflow-hidden bg-gray-50 border-2 border-gray-300 hover:border-yellow-500" >

                  <div class="flex-1 p-6 flex flex-col justify-between border-t-2 border-gray-200">
                    <div class="flex-1">

                      <p class="mt-3 text-base text-gray-500">
                        {{ testimonial.description }}
                      </p>

                    </div>

                  </div>

                <cite class="relative flex items-center sm:items-start bg-gradient-to-r from-red-500 to-amber-600 rounded-b-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
                <div class="relative rounded-full border-2 border-white sm:absolute sm:top-0 sm:transform sm:-translate-y-1/2">
                  <img class="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-indigo-300" :src="testimonial.imageUrl" alt="" />
                </div>
                <span class="relative ml-4 text-white font-semibold leading-6 sm:ml-24 sm:pl-1">
                  <p class="text-black font-semibold sm:inline">{{ testimonial.name }}</p>
                  {{ ' ' }}
                  <p class="sm:inline"><br>{{ testimonial.title }}</p>
                </span>
              </cite>

              </div>
            </div>
          </div>
        </div>

    </main>

      <!-- FOOTER -->
      <footer class="bg-black" aria-labelledby="footer-heading">
        <div class="max-w-7xl mx-auto pt-12 pb-5 px-4 sm:px-6 lg:pt-16 lg:px-8 text-center ">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3" >

                  <div class="flow-root bg-gray-800 rounded-lg p-6" id="gsapFooter">
                      <router-link :to="'/'" @click="scrollToAnchorPoint('top')" >
                      <div style="display: table-cell; text-align: center; vertical-align:middle;">
                        <img src="..\assets\logos\DevInspire_Logo_light.png" alt="Dev Inspire" />
                      </div>
                      </router-link>
                  </div>

                  <div class="flow-root bg-gray-800 rounded-lg px-6 pb-8; " style="text-align: center; " id="gsapFooter" >
                      <div class="relative text-md text-gray-300 font-medium mt-5 m-auto" style="width:230px;">
                        <div style="width: 100%; display: table;  ">
                            <div v-for="item in contact" :key="item.name" style="display: table-row;" >
                              <div style="display: table-cell; text-align:left;vertical-align:middle;" class="pb-3">
                                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                                </div>
                              <div style="display: table-cell; text-align:left;vertical-align:middle; width:200px" class="pb-3">
                                <a :href="item.href">{{ item.description }}</a>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>

                  <div class="flow-root bg-gray-800 rounded-lg p-8" id="gsapFooter">
                     <div class="relative text-md text-gray-200 font-medium mt-2 m-auto" style="width:300px;">
                      <div class="flex space-x-6 md:order-2">
                        <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" class="text-gray-200 hover:text-gray-500">
                          <span class="sr-only">{{ item.name }}</span>
                          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </a>
                      </div>
                      <p class="relative text-md text-gray-400 font-medium mt-5 text-left">
                        &copy; {{this.currentDate}} Dev Inspire. All rights reserved.
                      </p>
                      <p class="relative text-md text-gray-400 font-medium mt-5 text-left">
                        <u><router-link to="/cookiePolicy">Cookie Policy</router-link></u> | <u><a href="PAIA_Manual.pdf" target="_blank">PAIA Manual</a></u>
                      </p>
                     </div>
                  </div>
              </div>
        </div>
      </footer>

    </div>
  </div>
</template>

<style type="text/css">
@import url(http://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);

  .st0{fill:#121827;}
	.st1{opacity:0.18;fill:url(#SVGID_1_);enable-background:new    ;}
	.st2{fill:url(#SVGID_00000158006647321721361210000016935062320373562042_);fill-opacity:0.9;}
	.st3{opacity:0.15;fill:url(#SVGID_00000076588609204041264570000011005227168538422937_);enable-background:new    ;}
	.st4{opacity:0.15;fill:url(#SVGID_00000119088599438789331310000004297476440669111481_);enable-background:new    ;}
	.st5{fill:url(#SVGID_00000096767188792866565850000016733181594563812785_);}
	.st6{fill:url(#SVGID_00000057145644780979992420000012756330809197247628_);}
	.st7{fill:url(#SVGID_00000069397538609531579320000006084832040986801332_);}
	.st8{fill:url(#SVGID_00000164486473444415652850000015979682095887730327_);}
	.st9{opacity:0.7;fill:url(#blue_00000142172513343033236270000010714740224418581158_);enable-background:new    ;}
	.st10{opacity:0.7;fill:url(#blue_00000131335895414438618740000011920628042007273874_);enable-background:new    ;}
	.st11{opacity:0.7;fill:url(#SVGID_00000183941573548624278560000002305579022872842170_);enable-background:new    ;}
	.st12{opacity:0.7;fill:url(#SVGID_00000047056412927276260810000007667437809107020710_);enable-background:new    ;}
	.st13{opacity:0.7;fill:url(#blue_00000129208058822813067810000011469553403638958758_);enable-background:new    ;}
	.st14{opacity:0.7;fill:url(#SVGID_00000015337639953644825030000007959897750750367914_);enable-background:new    ;}
	.st15{opacity:0.7;}
	.st16{fill:url(#SVGID_00000157299931698356313360000006069481896051589305_);}
	.st17{opacity:0.7;fill:url(#SVGID_00000099627787809872150710000006785179140760651661_);enable-background:new    ;}
	.st18{opacity:0.7;fill:url(#SVGID_00000096758498998070212680000002747045722378475414_);enable-background:new    ;}
	.st19{opacity:0.7;fill:url(#SVGID_00000143600421099009494560000001179952509975429543_);enable-background:new    ;}
	.st20{opacity:0.7;fill:url(#circleSunmi_00000182513778361948824680000009613993266845293226_);enable-background:new    ;}
	.st21{opacity:0.7;fill:url(#red_00000010993117672205123750000006935832381226462120_);enable-background:new    ;}
	.st22{opacity:0.7;fill:url(#SVGID_00000009549490305103706300000005619885493436759230_);enable-background:new    ;}
	.st23{opacity:0.7;fill:url(#SVGID_00000000901575086988144430000005796597097207334289_);enable-background:new    ;}
	.st24{fill:#18A8EF;}
	.st25{fill:#D30B0B;}
	.st26{fill:#1B67ED;}
	.st27{fill:#DF2B50;}
	.st28{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
	.st29{fill:#FFFF9C;}
	.st30{fill:#BDDBFF;}
	.st31{fill:#FFFFFF;}
	.st32{fill:#52B080;}
	.st33{fill:#62AF52;}
	.st34{fill:#05ADAD;}
	.st35{fill:#FF6700;}
	.st36{fill:#FC2271;}
	.st37{fill:#740573;}
	.st38{opacity:0.6;enable-background:new    ;}
	.st39{font-family:'Roboto-Bold';}
	.st40{font-size:108.058px;}
	.st41{font-family:'Roboto-Regular';}
	.st42{font-size:27.0821px;}
	.st43{fill:#00D639;}
</style>

<script src=".\home.js"></script>
