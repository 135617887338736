import { defineComponent, h } from 'vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import {
  DesktopComputerIcon,
  ChatIcon,
  SpeakerphoneIcon,
  MenuIcon,
  TicketIcon,
  ShieldCheckIcon,
  XIcon,
  CubeTransparentIcon,
  TrendingUpIcon,
  PhoneIcon,
  MailIcon,
  LocationMarkerIcon
} from '@heroicons/vue/outline'
import { ChevronRightIcon, ExternalLinkIcon } from '@heroicons/vue/solid'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
//import LogoSVG from '../assets/images/circle_orangeGrad.svg';
gsap.registerPlugin(ScrollTrigger)

// https://vue-hero-icons.netlify.app

const navigation = [
  { name: 'Products', click: "scrollToAnchorPoint('productsBlock')" },
  { name: 'Contact Us', click: 'contact-us' }
]

const Dev = [
  {
    title: 'Mobile Development',
    category: { name: '', href: '#' },
    imageUrl: require('../assets/images/photo-1559526324-c1f275fbfa32.jpg'),
    preview:
      'Go mobile today! We develop native mobile applications - iOS & Android. We have made a wide variety of applications from tasking applications to games.'
  },
  {
    title: 'System Development & Integration',
    category: { name: '', href: '#' },
    imageUrl: require('../assets/images/photo-1624718666169-674e3779c524.jpg'),
    preview:
      'Web development, native development, system integration, and hardware development.'
  },
  {
    title: 'Graphic Design',
    category: { name: '', href: '#' },
    imageUrl:
    require('../assets/images/photo-1492724441997-5dc865305da7.jpg'),
    preview:
      'Logo design, branding, print.'
  },
  {
    title: 'APIs',
    category: { name: '', href: '#' },
    imageUrl: require('../assets/images/photo-1488590528505-98d2b5aba04b.jpg'),
    preview:
      'DevInspire develops Secure and extensible API\'s. APIs are needed to bring applications together in order to perform a designed function built around sharing data and executing pre-defined processes.'
  },
  {
    title: '3D development',
    category: { name: '', href: '' },
    imageUrl: require('../assets/images/VExpoLab_auditorium_002.jpg'),
    preview:
      'Virtual exhibitions, games, and custom journeys with immersed 3D environments.'
  }, {
    title: 'Hardware Development and Prototyping',
    category: { name: '', href: '#' },
    imageUrl: require('../assets/images/photo-1517077304055-6e89abbf09b0.jpg'),
    preview:
      'Need a little bit more than software? We distribute Tech too. Our tech development includes iOT, IC\'s, PIC\'s, telemetric monitoring and more.'
  }
]

const SpecialisedDev = [
  {
    name: 'Self-service kiosk solutions',
    description: 'Printing, scanning, integrations, COVID screening.',
    icon: DesktopComputerIcon
  },
  {
    name: 'Electronic raffles',
    description: 'E-Draws/Tombola Draws.',
    icon: TicketIcon
  },
  {
    name: 'Promotions and loyalty',
    description: 'Draws, games and leaderboards.',
    icon: SpeakerphoneIcon
  },
  {
    name: 'Advanced Security',
    description: 'We follow best practices and guidelines to ensure that your information is secure.',
    icon: ShieldCheckIcon
  },
  {
    name: 'Advantage systems',
    description:
        'Flexible systems delivered to the highest standards.',
    icon: TrendingUpIcon
  },
  {
    name: 'WhatsApp Business',
    description:
        'Facilitation of onboarding your business to WhatsApp business and automations.',
    icon: ChatIcon
  },
  {
    name: 'Bot services',
    description: 'Services across muliple channels - one bot for all.',
    icon: CubeTransparentIcon
  }
]

const products = [
  {
    name: 'Incenti-5',
    description: 'Promotional system for casinos and retailers, managing raffles, vouchers, promotions and customer interaction.',
    imageUrl: require('../assets/logos/incenti5.png'),
    href: '/incenti5'
  },
  {
    name: 'Walkabout',
    description: 'Job card and assessment platform to gauge staff performance and credibility of work done in the services industry.',
    imageUrl: require('../assets/logos/Walkabout.png'),
    href: '/Walkabout'
  },
  {
    name: 'Bika',
    description: 'A mobile corporate communications platform and feedback tool. Covers training, news, assessment, push notifications, KPA’s, task lists, covid screening, HR integration and more.',
    imageUrl: require('../assets/logos/Bika.png'),
    href: '/Bika'
  },
  {
    name: 'Tokaly',
    description: 'A digital marketing platform consisting of a large variety of interactive games and digital advertising.',
    imageUrl: require('../assets/logos/Tokaly.png'),
    href: '/Tokaly'
  },
  {
    name: 'VExpo',
    description: '3D virtual expo, trade show, product demo, conferencing and purchasing portal for the Retail sector.',
    imageUrl: require('../assets/logos/VExpoLab.png'),
    href: '/VExpoLab'
  },
  {
    name: 'Boom Rule Access',
    description: 'Manage user access by definable rules per access point.',
    imageUrl: require('../assets/logos/BoomRuleAccess.png'),
    href: '/BoomRuleAccess'
  }
]

const testimonials = [
  {
    name: 'Petro Magos',
    title: 'CEO Magos Media',
    description: 'DevInspire certainly live up to their name, Magos Media’s experience with them is always inspirational and the service is excellent. They have the ability to make things happen and always achieve the results we need. No task or project is too big or unachievable. They constantly strive to use the latest technologies and methodologies to deliver on the work required.',
    imageUrl: require('../assets/images/PetroMagos.png'),
    logoImageUrl: require('../assets/logos/MM-black.png')
  },
  {
    name: 'Letitia Lange',
    title: 'Director at Rail and Sail',
    description: 'I recommend DevInspire for website design and development, mobile and desktop app development as well as custom development. A young and dynamic team with amazing imaginations. I am extremely happy with the work they have done for my company.',
    imageUrl: require('../assets/images/Letitia-Langes-Profile-pic-Blank-backgroung.png'),
    logoImageUrl: require('../assets/logos/RailAndSail.png')
  },
  {
    name: 'Marian Rymaszewski',
    title: 'CA (SA) at Suburban Services',
    description: 'Jason and his team at Devinpire are top class, we at Suburban Services have been dealing with them for the past 4 years. Every interaction was professional and on point. The team is knowledgeable, dependable, and always puts the customer first.',
    imageUrl: require('../assets/images/MarianRymaszewski.png'),
    logoImageUrl: require('../assets/logos/suburbanserv.png')
  },
  {
    name: 'Anesca Dorfling',
    title: 'Director at Chain Research',
    description: 'This great IT team wrote amazing software for our business. Their knowledge and professionalism are highly commendable. Their after sales service has also been fantastic. Thanks very much guys for a job well done!',
    imageUrl: require('../assets/images/Anesca-Dorfling-Profile-picture-Blank-background.png'),
    logoImageUrl: require('../assets/logos/Chain-Research-Logo.png')
  }
]

const companies = [
  {
    name: 'Dev IT',
    href: 'https://devit.co.za',
    imageUrl: require('../assets/logos/DevIT.png')
  },
  {
    name: 'Magos Media',
    href: 'https://magosmedia.co.za',
    imageUrl: require('../assets/logos/MM-black.png')
  },
  {
    name: 'Sun International',
    href: 'https://www.suninternational.com',
    imageUrl: require('../assets/logos/SunInternational.png')
  },
  {
    name: 'Rail and Sail Logistics',
    href: 'https://railandsail.co.za',
    imageUrl: require('../assets/logos/RailAndSail.png')
  },
  {
    name: 'Chain Research',
    href: 'https://chainresearch.co.za',
    imageUrl: require('../assets/logos/Chain-Research-Logo.png')
  },
  {
    name: 'Spar',
    href: 'https://www.spar.co.za',
    imageUrl: require('../assets/logos/spar.png')
  },
  {
    name: 'Pick n Pay',
    href: 'https://www.pnp.co.za',
    imageUrl: require('../assets/logos/pnp.png')
  },
  {
    name: 'Peermont',
    href: 'https://www.peermont.com',
    imageUrl: require('../assets/logos/peermont.png')
  },
  {
    name: 'Dwarsrivier',
    href: 'https://www.assore.com/',
    imageUrl: require('../assets/logos/dwarsrivier.png')
  },
  {
    name: 'Gensys Workforce Systems',
    href: 'https://www.gensys.co.za/',
    imageUrl: require('../assets/logos/gensys.png')
  },
  {
    name: 'suburbanserv',
    href: 'http://suburbanserv.co.za/',
    imageUrl: require('../assets/logos/suburbanserv.png')
  },
  {
    name: 'Emerald Casino',
    href: 'https://www.emeraldcasino.co.za/',
    imageUrl: require('../assets/logos/emerald.png')
  },
  {
    name: 'Nissan',
    href: 'https://www.nissan.co.za/',
    imageUrl: require('../assets/logos/nissan-logo.png')
  },
  {
    name: 'HarviRex',
    href: 'https://www.facebook.com/Harvirex/',
    imageUrl: require('../assets/logos/HarviRex.png')
  }
]

const contact = [
  {
    name: 'Phone',
    description: ' +27 (0)11 312 9409',
    icon: PhoneIcon
  },
  {
    name: 'E-Mail',
    description: 'info@devinspire.co.za',
    href: 'mailto:info@devinspire.co.za',
    icon: MailIcon
  },
  {
    name: 'Address',
    description: 'Midrand, South Africa',
    icon: LocationMarkerIcon
  }
]

const footerNavigation = {
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/DevInspireTeam',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d:
                'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/devinspiresa/',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d:
                'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/DevInspireSA',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              d:
                'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84'
            })
          ])
      })
    }
  ]
}

export default {
  name: 'Home',
  data: function () {
    return {
      hash: this.$route.hash
    }
  },
  mounted: function () {
    gsap.from('#gsapHeader', { stagger: { amount: 1, from: 'left' }, x: -200, opacity: 0, duration: 1 })

    // gsap.from('#donut', { y: -50, duration: 2 })
    // gsap.from('#CircleTop', { y: -50, duration: 2 })
    // gsap.from('#CircleBig', { x: 10, duration: 2 })
    // gsap.from('#CircleBottom', { x: 50, duration: 2 })

    const tl = gsap.timeline({ defaults: { duration: 0.7, opacity: 0 } })// ease: Tween.Back.easeOut.config(2),
    tl.from('#donut', { delay: 2, scale: 0.7, transformOrigin: 'center', y: -50}, '-=.2')
    tl.from('#CircleTop', { delay: 0, scale: 0.7, transformOrigin: 'center', y: -50 }, '-=.2')
    //tl.from('#CircleBig', { delay: 0, scale: 0.7, transformOrigin: 'center', rotate: 10 }, '-=.2')
    tl.from('#CircleBottom', { delay: 0, scale: 0.7, transformOrigin: 'center', y: 50 }, '-=.5')
    //tl.from('#CircleBig', { delay: 0, scale: 0.9, transformOrigin: 'center', ease: 'Tween.Back.easeOut.config(2)', duration: 2 }, '-=.1')
    tl.from('#CircleBig', { delay: 0, scale: 0.9, transformOrigin: 'center', y: -300 }, '-=2')
    tl.to('#CircleBig', { opacity: 100, duration: 5 })
    tl.yoyo(true).repeat(-1).play(0.5)

    gsap.from('#gsapDev', { stagger: { amount: 1, from: 'left', grid: 'auto' }, opacity: 0 })

    gsap.from('#gsapSpDev', { scrollTrigger: { trigger: '#gsapSpDev', start: 'top bottom', end: 'bottom bottom', toggleActions: 'play none none none' }, stagger: { amount: 1, from: 'center', grid: 'auto' }, y: -200, opacity: 0, duration: 1 })
    gsap.from('#gsapFooter', { scrollTrigger: { trigger: '#gsapFooter', start: 'top bottom', end: 'bottom bottom', toggleActions: 'play none reverse none' }, stagger: { amount: 1, from: 'left' }, y: -200, opacity: 0, duration: 1 })

    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace('#', '')
        this.scrollToAnchorPoint(refName)
      }
    })
  },
  methods: {
    scrollToAnchorPoint (refName) {
      const el = this.$refs[refName]
      el.scrollIntoView({ behavior: 'smooth' })
    }
  },
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronRightIcon,
    ExternalLinkIcon,
    MenuIcon,
    XIcon,
    PhoneIcon,
    MailIcon,
    ChatIcon,
    LocationMarkerIcon,
    //'logo-svg':LogoSVG
  },
  setup () {
    return {
      navigation,
      Dev,
      SpecialisedDev,
      products,
      testimonials,
      companies,
      contact,
      footerNavigation
    }
  }

}
