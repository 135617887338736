<template>
  <div class="bg-white">
    <div class="relative overflow-hidden">

        <div class="bg-black pt-6">
          <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
            <div class="md:flex items-center flex-1">
              <div class="flex items-center w-full">
                <router-link :to="'/'">
                  <span class="sr-only">Workflow</span>
                  <img class="h-10 w-auto sm:h-16" src="..\assets\logos\DevInspire_Logo.png" alt="" />
                </router-link>
              </div>

              <div class="md:flex md:ml-10 text-gray-300" >
                <router-link :to="'/#productsBlock'" class="text-base font-bold hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Products</router-link>&nbsp;
                <span class="text-base font-medium text-yellow-500">></span>&nbsp;
                <span class="text-base font-bold text-yellow-500">Incenti5</span>&nbsp;
                <span class="w-2">&middot;</span><router-link to="/Walkabout" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Walkabout</router-link>&nbsp;
                <span class="w-2">&middot;</span><router-link to="/Bika" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Bika</router-link>&nbsp;
                <span class="w-2">&middot;</span><router-link to="/Tokaly" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Tokaly</router-link>&nbsp;
                <span class="w-2">&middot;</span><router-link to="/VExpoLab" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">VExpoLab</router-link>&nbsp;
                <span class="w-2">&middot;</span><router-link to="/BoomRuleAccess" class="text-base font-medium hover:text-white w-36" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Boom Rule Access</router-link>
              </div>

            </div>
          </nav>
        </div>

        <PageHeader/>

        <!-- Benefits section -->
        <div class="relative bg-gray-500 py-16 sm:py-24 lg:py-32">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <p class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
                Benefits
              </p>
              <p class="mt-5 mx-auto max-w-prose text-xl text-gray-500">

              </p>
            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">

              <item-card-icon
                title='Self Service'
                titleBlockStyle="flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-orange-500 to-pink-500"
                imageName = 'kiosk.svg'
                preview = 'Make use of Incenti5’s customizable self service kiosks.'
                />

              <item-card-icon
                title='Integration'
                titleBlockStyle="flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-pink-500 to-indigo-600"
                imageName = 'integration.svg'
                preview = 'Mobile, Web and POS systems.'
                />

              <item-card-icon
                title='Rewards'
                titleBlockStyle="flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-indigo-600 to-purple-600"
                imageName = 'open-present-box-svgrepo-com.svg'
                preview = 'Customers rewarded with vouchers, draw tickets and games with various outcomes.'
                />

              <item-card-icon
                title='Incentives'
                titleBlockStyle="flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-orange-500 to-pink-500"
                imageName = 'trophy-icon.svg'
                preview = 'Drive play by incentivising customers for points targets reached.'
                />
              <item-card-icon
                title='Increase Visits'
                titleBlockStyle="flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-pink-500 to-indigo-600"
                imageName = 'signup-icon.svg'
                preview = 'Drive additional visits through visit based multipliers during specific times.'
                />
            </div>
          </div>
        </div>

        <!-- Integrations section -->
        <div class="relative bg-gray-700 py-16 sm:py-24 lg:py-32">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <p class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
                Integration
              </p>
              <p class="mt-5 mx-auto max-w-prose text-xl text-gray-300">
                Incenti5 is a tool designed for the gaming industry as a customer engagement and promotions tool.
              </p>
              <div class="text-gray-300 mt-12 mb-5 text-left">Incenti5 is integrated with... </div>

            </div>
            <div class=" mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 md:grid-cols-3 lg:grid-cols-6 lg:max-w-7xl text-center">

                <div class="flex-1 p-6 flex flex-col rounded-lg justify-between bg-gradient-to-r from-orange-500 to-pink-500">
                  <div class="flex-1">
                      <p class="text-xl font-semibold text-gray-50">
                         Bally
                      </p>
                      <p class="mt-3 text-base text-gray-200"></p>
                  </div>
                </div>

                <div class="flex-1 p-6 flex flex-col rounded-lg justify-between bg-gradient-to-r from-pink-500 to-indigo-600">
                  <div class="flex-1">
                      <p class="text-xl font-semibold text-gray-50">
                         ACE
                      </p>
                      <p class="mt-3 text-base text-gray-200"></p>
                  </div>
                </div>

                <div class="flex-1 p-6 flex flex-col rounded-lg justify-between bg-gradient-to-r from-indigo-600 to-purple-600">
                  <div class="flex-1">
                      <p class="text-xl font-semibold text-gray-50">
                         DRGT
                      </p>
                      <p class="mt-3 text-base text-gray-200"></p>
                  </div>
                </div>

                <div class="flex-1 p-6 flex flex-col rounded-lg justify-between bg-gradient-to-r from-orange-500 to-pink-500">
                  <div class="flex-1">
                      <p class="text-xl font-semibold text-gray-50">
                         Galaxis and Advantage
                      </p>
                      <p class="mt-3 text-base text-gray-300">In progress</p>
                  </div>
                </div>

                <div class="flex-1 p-6 flex flex-col rounded-lg justify-between bg-gradient-to-r from-pink-500 to-indigo-600">
                  <div class="flex-1">
                      <p class="text-xl font-semibold text-gray-50">
                         Playtech
                      </p>
                      <p class="mt-3 text-base text-gray-300">In progress</p>
                  </div>
                </div>



            </div>
          </div>
        </div>

        <!-- Installations section -->
        <div class="relative bg-gray-900 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
                Installations
              </h2>
              <p class="mx-auto max-w-prose text-lg text-gray-200">
					      Installed in over 20 casinos
              </p>
            </div>
            <!-- <div class="mt-12 mx-auto max-w-4xl bg-cover">
              <div class="flex flex-col rounded-lg overflow-hidden " >
                    <img class=" w-full " src="..\assets\images\incenti5\Installations.svg" alt="" />
              </div>
            </div> -->
          </div>
        </div>

        <!-- MobileShowcase section -->
        <div class="relative bg-gray-700 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">

              <p class="text-4xl font-extrabold  tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
                Incenti5 Mobile
              </p>
              <p class="mx-auto max-w-prose text-lg text-gray-200">
              Customers can enter Draws and claim Vouchers anywhere.</p>
            </div>

            <!-- <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 grid-cols-2  md:grid-cols-3  md:max-w-5xl">

              <item-card-image
                title=''
                preview = ''
                titleStyle = 'flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-orange-500 to-pink-500'
                imageName = 'mobile/Mobile_SunInt_1.jpg'
                />

              <item-card-image
                title=''
                preview = ''
                titleStyle = 'flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-pink-500 to-indigo-600'
                imageName = 'mobile/Mobile_SunInt_draw1.jpg'
                />

              <item-card-image
                title=''
                preview = ''
                titleStyle = 'flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-indigo-600 to-purple-600'
                imageName = 'mobile/Mobile_SunInt_draw2.jpg'
                />

                <item-card-image
                title=''
                preview = ''
                titleStyle = 'flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-orange-500 to-pink-500'
                imageName = 'mobile/Mobile_SunInt_v1.jpg'
                />

              <item-card-image
                title=''
                preview = ''
                titleStyle = 'flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-pink-500 to-indigo-600'
                imageName = 'mobile/Mobile_SunInt_v2.jpg'
                />

              <item-card-image
                title=''
                preview = ''
                titleStyle = 'flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-indigo-600 to-purple-600'
                imageName = 'mobile/Mobile_SunInt_v3.jpg'
                />

            </div> -->
          </div>
        </div>

		<!-- Rewards section -->
		<div class="relative bg-gray-500 py-16 sm:py-24 lg:py-32">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <p class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
                Vouchers & Rewards
              </p>
            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">

              <item-card-icon
                title='Vouchers and voucher redemption'
                titleBlockStyle="flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-orange-500 to-pink-500"
                imageName = 'qr-code-icon.svg'
                preview = 'Issue vouchers and manage the redemption process. Prevent fraud.'
                />

              <item-card-icon
                title='Incentive Games'
                titleBlockStyle="flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-pink-500 to-indigo-600"
                imageName = 'gaming-pc-icon.svg'
                preview = 'Engage customers with fun table/kiosk based games.'
                />

              <item-card-icon
                title='Non-gaming rewards'
                titleBlockStyle="flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-indigo-600 to-purple-600"
                imageName = 'surprise-icon.svg'
                preview = 'Reward guests for special days (e.g. Birthday or Anniversary).'
                />

            </div>
          </div>
        </div>

        <!-- DrawTypes section -->
        <div class="relative bg-gray-900 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative text-center">

				<p class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
					Draw Types
				</p>
            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">

              <item-card-image
                title='Manual Draws'
                preview = 'Tickets printed at kiosk and entered into the tombola.'
                titleStyle = 'flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-orange-500 to-pink-500'
                imageName = 'lotto_screenshot_2.jpg'
                />

              <item-card-image
                title='Electronic Draws'
                preview = 'Players only print receipt with digital ticket numbers.'
                titleStyle = 'flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-pink-500 to-indigo-600'
                imageName = 'fireworksdraw_sample_1.jpg'
                />

              <item-card-image
                title='Golden Number Draws'
                preview = 'Customer participation during draw process.'
                titleStyle = 'flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-indigo-600 to-purple-600'
                imageName = 'GoldenNumberDraw_3.jpg'
                />

            </div>
          </div>
        </div>

        <!-- DrawMechanics section -->
        <div class="relative bg-gray-500 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">

				<p class="text-4xl font-extrabold  tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
					Draw Mechanics
				</p>
            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">

              <item-card-image
                title='Fireworks Draws'
                preview = ''
                titleStyle = 'flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-orange-500 to-pink-500'
                imageName = 'fireworksdraw_sample_2.jpg'
                />

              <item-card-image
                title='Tombola Draws'
                preview = ''
                titleStyle = 'flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-pink-500 to-indigo-600'
                imageName = 'lotto_screenshot_1.jpg'
                />

              <item-card-image
                title='Slot Draws'
                preview = ''
                titleStyle = 'flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-indigo-600 to-purple-600'
                imageName = 'PromoGames_SlotDraw_v001.jpg'
                />

            </div>
          </div>
        </div>

		<!-- Payment section -->
        <div class="relative bg-gray-900 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
				<p class="text-4xl font-extrabold  tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
					Payment to Card
				</p>
				<p class="mx-auto max-w-prose text-lg text-gray-200">
					Freeplay vouchers have never been easier!
              </p>
            </div>
            <div class="mt-5 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8  lg:max-w-xl">
              	<div class="flex flex-col align-middle text-center overflow-hidden" >

					<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <g id="CircleBig">
                <g>
                  <defs>
                    <ellipse id="SVGID_1_" cx="253.3" cy="259.3" rx="241.2" ry="251.1"/>
                  </defs>
                  <clipPath id="SVGID_00000175321163492509818470000003996024875876001153_">
                    <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
                  </clipPath>
                    <linearGradient id="SVGID_00000006701603617012083530000005921839333719833526_" gradientUnits="userSpaceOnUse" x1="-766.1763" y1="-0.5064" x2="-765.5112" y2="-0.5064" gradientTransform="matrix(-754.9999 754.9999 -754.9999 -754.9999 -578340.9375 578088.875)">
                    <stop  offset="0" style="stop-color:#EB9B22"/>
                    <stop  offset="1.373630e-03" style="stop-color:#EB9B22"/>
                    <stop  offset="1" style="stop-color:#FF5454"/>
                  </linearGradient>
                    <polygon style="clip-path:url(#SVGID_00000175321163492509818470000003996024875876001153_);fill:url(#SVGID_00000006701603617012083530000005921839333719833526_);" points="
                    735.6,259.3 253.3,761.5 -229.1,259.3 253.3,-242.9"/>
                </g>
              </g>
              <image x="180" y="50" href="../assets/icons/payment-dollar-icon.svg"/>
					  <text style="fill:white;" class="text-xl">
						<tspan x="50" y="250" >Incenti5 allows for payment to card or account</tspan>
						<tspan x="60" y="300" >Payment types are cash, promo, and points <tspan style="fill:lightgray;">*</tspan></tspan>
						<tspan x="80" y="400" class="text-lg" style="fill:lightgray;">* Subject to provider integration availability.</tspan>
					</text>
					</svg>
				</div>
			</div>
		</div>
		</div>

		<!-- Points Redemption section -->
        <div class="relative bg-gray-500 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
				<h2 class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
					Points Redemption
        </h2>
				<p class="mx-auto max-w-prose text-lg text-gray-200">
					Incenti-5 allows for spending points from the customers card or account.
              </p>
            </div>
            <div class="mt-5 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8  lg:max-w-xl">
              	<div class="flex flex-col align-middle text-center overflow-hidden" >

					<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <g id="CircleBig">
              <g>
                <defs>
                  <ellipse id="SVGID_1_" cx="253.3" cy="259.3" rx="241.2" ry="251.1"/>
                </defs>
                <clipPath id="SVGID_00000175321163492509818470000003996024875876001153_">
                  <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
                </clipPath>
                  <linearGradient id="SVGID_00000006701603617012083530000005921839333719833526_" gradientUnits="userSpaceOnUse" x1="-766.1763" y1="-0.5064" x2="-765.5112" y2="-0.5064" gradientTransform="matrix(-754.9999 754.9999 -754.9999 -754.9999 -578340.9375 578088.875)">
                  <stop  offset="0" style="stop-color:#EB9B22"/>
                  <stop  offset="1.373630e-03" style="stop-color:#EB9B22"/>
                  <stop  offset="1" style="stop-color:#FF5454"/>
                </linearGradient>
                  <polygon style="clip-path:url(#SVGID_00000175321163492509818470000003996024875876001153_);fill:url(#SVGID_00000006701603617012083530000005921839333719833526_);" points="
                  735.6,259.3 253.3,761.5 -229.1,259.3 253.3,-242.9"/>
              </g>
            </g>
            <image x="170" y="50" href="../assets/icons/open-present-box-svgrepo-com.svg"/>
					  <text style="fill:white;" class="text-xl">
						<tspan x="100" y="250" >Reduce points liability by adding</tspan>
						<tspan x="80" y="300" >the purchase criteria to a promotion. <tspan style="fill:lightgray;">*</tspan></tspan>
						<tspan x="80" y="400" class="text-lg" style="fill:lightgray;">* Subject to provider integration availability.</tspan>
					</text>
					</svg>
				</div>
			</div>
		</div>
		</div>

        <!-- Stage Games section -->
        <div class="relative bg-gray-900 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">

              <p class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
                Stage Games
              </p>
              <p class="mt-3 mx-auto max-w-prose text-lg text-gray-200">
                Live games are designed to bring personal interaction back to the casino floor.
              </p>
              <div class="text-sm text-gray-400 mt-3" >
                Customer can play instant games based on management discretion. The game is played on a tablet or mobile device, but is broadcast to CIS displays. The same games can be played on PTU/Kiosk as reward for achievement. Games can be customized for customers.
              </div>

            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-2 lg:max-w-7xl">

              <stage-game
                title=''
                titleStyle = 'flex-1 p-6 flex flex-col justify-between bg-gradient-to-r from-orange-500 to-red-500'
                preview = ''
                imageName = 'stageGames.png'
                />

              <stage-game
                title=''
                preview = ''
                imageName = 'VID-20220812-WA0031.jpg'
                />

            </div>
          </div>
        </div>


		<!-- Promo Games section -->
        <div class="relative bg-gray-500 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">

              <p class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
                Promo Games
              </p>

            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">

              <promo-game imageName = 'darts.jpg' alt='Dart'/>
              <promo-game imageName = 'dynamite.jpg' alt='Dynamite'/>
              <promo-game imageName = 'fishy.jpg' alt='Fishy'/>
              <promo-game imageName = 'goal.jpg' alt='Goal'/>
              <promo-game imageName = 'levelup.jpg' alt='Level Up'/>
              <promo-game imageName = 'spinthewheel.jpg' alt='Spin the Wheel'/>
              <promo-game imageName = 'spellit.jpg' alt='Spell It'/>
              <promo-game imageName = 'pickabox.jpg' alt='Pick a Box'/>
              <promo-game imageName = 'prizecall.jpg' alt='Prize Call'/>
              <promo-game imageName = 'TreasurePick.jpg' alt='Treasure Pick'/>
              <promo-game imageName = 'vault.jpg' alt='Vault'/>

            </div>
          </div>
        </div>

		<!-- Qualification section -->
        <div class="relative bg-gray-900 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
                Qualification Criteria
              </h2>
            </div>
            <div class="mt-12 mx-auto max-w-lg bg-cover">
              <div class="flex flex-col rounded-lg overflow-hidden " >
                    <img class="h-72  w-full " src="..\assets\images\incenti5\Qualification.svg" alt="" />
              </div>
            </div>
          </div>
        </div>


		<!-- Participation section -->
        <div class="relative bg-gray-500 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
                Participation Criteria
              </h2>
			        <h3 class=" tracking-tight sm:text-3xl text-slate-300 pb-2">at a glance</h3>
            </div>
            <div class="mt-12 mx-auto max-w-lg bg-cover">
              <div class="flex flex-col rounded-lg overflow-hidden " >
                    <img class="h-72  w-full " src="..\assets\images\incenti5\Participation.svg" alt="" />
              </div>
            </div>
          </div>
        </div>


      <!-- CustomerJourneys section -->
            <div class="relative bg-gray-900 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
                Customer Journeys
              </h2>
            </div>
            <div class="mx-auto max-w-7xl px-4 " >
              <div class="flex flex-col rounded-lg overflow-hidden " >
                    <img style="height:500px" class=" w-full " src="..\assets\images\incenti5\CustomerJourneys.svg" alt="" />
              </div>
            </div>
          </div>
        </div>

      <!-- RoadMap section -->

      <div class="relative bg-slate-500 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">

				<h2 class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
					Road Map
        </h2>
            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">

              <road-map-item
                title='Micros Integration'
                titleStyle="text-xl font-semibold bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text"
                preview = 'Micros Point of Sale integration allows for redemption of vouchers and more.'
                imageName = 'RoadMap_Micros.svg'
                />

              <road-map-item
                title='Gamification'
                titleStyle="text-xl font-semibold bg-gradient-to-r from-pink-400 to-indigo-400 inline-block text-transparent bg-clip-text"
                preview = 'Criteria and segmentation driven games with configurable odds and rewards.'
                imageName = 'RoadMap_Gamification.svg'
                />

              <road-map-item
                title='Group Wide Promotions'
                titleStyle="text-xl font-semibold bg-gradient-to-r from-indigo-400 to-sky-300 inline-block text-transparent bg-clip-text"
                preview = 'A centralised promotions manager enables group wide draws, vouchers and other promotions.'
                imageName = 'RoadMap_GroupWide.svg'
                />

            </div>
          </div>
        </div>

      <!-- FOOTER -->

      <footer class="bg-black" aria-labelledby="footer-heading">
        <div class="max-w-7xl mx-auto pt-12 pb-5 px-4 sm:px-6 lg:pt-16 lg:px-8 text-center ">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3" >

                  <div class="flow-root bg-gray-800 rounded-lg p-6" id="gsapFooter">
                      <router-link :to="'/'" @click="scrollToAnchorPoint('top')" >
                      <div style="display: table-cell; text-align: center; vertical-align:middle;">
                        <img src="..\assets\logos\DevInspire_Logo_light.png" alt="Dev Inspire" />
                      </div>
                      </router-link>
                  </div>

                  <div class="flow-root bg-gray-800 rounded-lg px-6 pb-8; " style="text-align: center; " id="gsapFooter" >
                      <div class="relative text-md text-gray-300 font-medium mt-5 m-auto" style="width:230px;">
                        <div style="width: 100%; display: table;  ">
                            <div v-for="item in contact" :key="item.name" style="display: table-row;" >
                              <div style="display: table-cell; text-align:left;vertical-align:middle;" class="pb-3">
                                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                                </div>
                              <div style="display: table-cell; text-align:left;vertical-align:middle; width:200px" class="pb-3">
                                <a :href="item.href">{{ item.description }}</a>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>

                  <div class="flow-root bg-gray-800 rounded-lg p-8" id="gsapFooter">
                     <div class="relative text-md text-gray-200 font-medium mt-2 m-auto" style="width:300px;">
                      <div class="flex space-x-6 md:order-2">
                        <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" class="text-gray-200 hover:text-gray-500">
                          <span class="sr-only">{{ item.name }}</span>
                          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </a>
                      </div>
                      <p class="relative text-md text-gray-400 font-medium mt-5 text-left">
                        &copy; {{this.currentDate}} Dev Inspire. All rights reserved.
                      </p>
                      <p class="relative text-md text-gray-400 font-medium mt-5 text-left">
                        <u><router-link to="/cookiePolicy">Cookie Policy</router-link></u> | <u><a href="PAIA_Manual.pdf" target="_blank">PAIA Manual</a></u>
                      </p>
                     </div>
                  </div>
              </div>
        </div>
      </footer>
    </div>

</template>

<script src=".\incenti5.js"></script>
<style type="text/css">
	.st18{fill:#FFFFFF;}
</style>

<style type="text/css">
	.st0{display:none;}

	.st2{opacity:0.2;enable-background:new    ;}
	.st3{opacity:0.5;fill:#FFFFFF;}
	.st4{fill:#FFFFFF;}
	.st5{opacity:0.2;fill:#FFFFFF;enable-background:new    ;}
	.st6{opacity:0.8;}
	.st7{fill:url(#SVGID_00000017497374806615638380000003107617157833173662_);}
</style>
