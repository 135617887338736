<template>
	<div class="flex flex-col rounded-lg shadow-lg overflow-hidden bg-slate-900">
		<div class="flex-shrink-0 ">
		<img class="h-64 w-full object-cover mt-3" :src="require('@/assets/images/incenti5/' + imageName + '')" :alt="title" />
		</div>
		<div class="flex-1 p-6 flex flex-col ">
		<div class="flex-1">
			<p :class="titleStyle">
				{{ title }}
			</p>
			<p class="mt-3 text-base text-gray-400">
				{{ preview }}
			</p>
		</div>
		</div>
	</div>
</template>

<script >
export default {
  props: ['title', 'titleStyle', 'preview', 'imageName']
};
</script>