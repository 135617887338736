<template>
  <div class="bg-white">
    <div class="relative overflow-hidden">

        <div class="bg-black pt-6">
          <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
            <div class="flex items-center flex-1">
              <div class="flex items-center justify-between w-full md:w-auto">
                <router-link :to="'/'">
                  <span class="sr-only">Workflow</span>
                  <img class="h-10 w-auto sm:h-16" src="..\assets\logos\DevInspire_Logo.png" alt="" />
                </router-link>

              </div>
              <div class=" space-x-8 md:flex md:ml-10">
                <a v-for="item in navigation" :key="item.name" :href="item.href" class="text-base font-medium text-white hover:text-gray-300">{{ item.name }}</a>
              </div>
            </div>
          </nav>
        </div>
      <main>

      <!-- CookiePolicy -->
      <div class="relative bg-white">
      <div class="lg:absolute lg:inset-0">
        <div class="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
          <img class="h-56 w-full object-cover lg:absolute lg:h-full" src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80" alt="" />
        </div>
      </div>
      <div class="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div class="lg:col-start-2 lg:pl-8">
          <div class="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
            <h2 class="leading-6 text-yellow-600 font-semibold tracking-wide uppercase">Cookie Policy</h2>
            <h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">What are cookies and why do we use them?</h3>
            <div class="mt-8 text-lg prose prose-yellow text-gray-500">
              <p>The information we collect from cookies enables us to:</p>
                  <ul style="list-style-type: none;">
                    <li>Tailor our websites to your personal needs.</li>
                    <li>Remember the notifications that you have been shown, so that you are not shown them again.</li>
                    <li>Make improvements and updates to our websites based on the way you want to use them.</li>
                    <li>We generally do not use cookies to identify you personally.</li>
                  </ul>
              <h3>What kind of cookies do we use?</h3>
              <p>Google Analytics (universal):<br>we are using Google Universal Analytics to monitor our website performances and check visitor activities to adapt our content based on their expectations.</p>
              <h3>How can you manage your cookie settings?</h3>
              <p>To ensure you get the best possible experience when visiting our websites, we recommend that you accept cookies. However, you can opt-out of each cookie category (except strictly necessary cookies) by clicking on the “cookie settings” button.</p>

            </div>
          </div>
        </div>
      </div>
    </div>
    </main>

      <!-- FOOTER -->
      <footer class="bg-gray-200" aria-labelledby="footer-heading" id="products">
        <div class="max-w-7xl mx-auto pt-12 pb-5 px-4 sm:px-6 lg:pt-16 lg:px-8 text-center zIndex:1">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3" >

                  <div class="flow-root bg-gray-100 rounded-lg p-6" id="gsapFooter">
                      <router-link :to="'/'" @click="scrollToAnchorPoint('top')" >
                        <div style="display: table-cell; text-align: center; vertical-align:middle;">
                          <img  src="..\assets\logos\DevInspire_Logo_dark.png" alt="Dev Inspire" />
                        </div>
                      </router-link>
                  </div>

                  <div class="flow-root bg-gray-100 rounded-lg px-6 pb-8; " style="text-align: center; " id="gsapFooter" >
                      <div class="relative text-md text-gray-700 font-medium mt-5 m-auto" style="width:230px;">
                        <div style="width: 100%; display: table;  ">
                            <div v-for="item in contact" :key="item.name" style="display: table-row;" >
                              <div style="display: table-cell; text-align:left;vertical-align:middle;" class="pb-3">
                                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                                </div>
                              <div style="display: table-cell; text-align:left;vertical-align:middle; width:200px" class="pb-3">
                                <a :href="item.href">{{ item.description }}</a>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>

                  <div class="flow-root bg-gray-100 rounded-lg p-8" id="gsapFooter">
                     <div class="relative text-md text-gray-700 font-medium mt-2 m-auto" style="width:300px;">
                      <div class="flex space-x-6 md:order-2">
                        <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" class="text-gray-400 hover:text-gray-500">
                          <span class="sr-only">{{ item.name }}</span>
                          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </a>
                      </div>
                      <p class="relative text-md text-gray-500 font-medium mt-5 text-left">
                        &copy; 2021 Dev Inspire. All rights reserved.
                      </p>
                     </div>
                  </div>
              </div>

        </div>
      </footer>
    </div>
  </div>
</template>

<script src=".\cookiePolicy.js"></script>

<link rel="stylesheet" type="text/css" href="https://unpkg.com/vue-cookie-accept-decline@5.4.0/dist/vue-cookie-accept-decline.css">
