<template>
  <div class="bg-black min-h-full">
    <div class="relative overflow-hidden">

        <div class="bg-black pt-6">
          <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
            <div class="flex items-center flex-1">
              <div class="flex items-center justify-between w-full md:w-auto">
                <router-link :to="'/'">
                  <span class="sr-only">Workflow</span>
                  <img class="h-10 w-auto sm:h-16" src="..\assets\logos\DevInspire_Logo.png" alt="" />
                </router-link>

              </div>
              <div class="sm:space-x-3 md:space-x-5 md:flex md:ml-10 text-gray-300" >
                <router-link :to="'/#productsBlock'" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Products</router-link>&nbsp;

                <span class="text-base font-bold text-yellow-500">Page not found</span>&nbsp;
              </div>
            </div>
          </nav>
        </div>

      <main>
        <div class="pt-10 bg-gray-800 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div class="mx-auto max-w-7xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div class="lg:py-24">
                  <!--<a href="#" class="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200">
                    <span class="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-gradient-to-r from-yellow-500 to-yellow-700 rounded-full">We're hiring</span>
                    <span class="ml-4 text-sm">Visit our careers page</span>
                    <ChevronRightIcon class="ml-2 w-5 h-5 text-gray-500" aria-hidden="true" />
                  </a>-->
                  <h1 class="mt-4 text-4xl tracking-tight font-extrabold sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-700 sm:pb-5" id="gsapHeader">
                    404 Error
                  </h1>
                  <div class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl" id="gsapHeader">
                    The requested URL was not found on this server.</div>
                </div>
              </div>
              <div class="mt-12 mb-0 sm:mb-48 lg:m-0 lg:relative">
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 ">
                  <div class="container" id="gsapHeaderImage">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    </main>

      <!-- FOOTER -->
      <footer class="bg-black" aria-labelledby="footer-heading">
        <div class="max-w-7xl mx-auto pt-12 pb-5 px-4 sm:px-6 lg:pt-16 lg:px-8 text-center ">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3" >

                  <div class="flow-root bg-gray-800 rounded-lg p-6" id="gsapFooter">
                      <router-link :to="'/'" @click="scrollToAnchorPoint('top')" >
                      <div style="display: table-cell; text-align: center; vertical-align:middle;">
                        <img src="..\assets\logos\DevInspire_Logo_light.png" alt="Dev Inspire" />
                      </div>
                      </router-link>
                  </div>

                  <div class="flow-root bg-gray-800 rounded-lg px-6 pb-8; " style="text-align: center; " id="gsapFooter" >
                      <div class="relative text-md text-gray-300 font-medium mt-5 m-auto" style="width:230px;">
                        <div style="width: 100%; display: table;  ">
                            <div v-for="item in contact" :key="item.name" style="display: table-row;" >
                              <div style="display: table-cell; text-align:left;vertical-align:middle;" class="pb-3">
                                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                                </div>
                              <div style="display: table-cell; text-align:left;vertical-align:middle; width:200px" class="pb-3">
                                <a :href="item.href">{{ item.description }}</a>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>

                  <div class="flow-root bg-gray-800 rounded-lg p-8" id="gsapFooter">
                     <div class="relative text-md text-gray-200 font-medium mt-2 m-auto" style="width:300px;">
                      <div class="flex space-x-6 md:order-2">
                        <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" class="text-gray-200 hover:text-gray-500">
                          <span class="sr-only">{{ item.name }}</span>
                          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </a>
                      </div>
                      <p class="relative text-md text-gray-400 font-medium mt-5 text-left">
                        &copy; {{this.currentDate}} Dev Inspire. All rights reserved.
                      </p>
                      <p class="relative text-md text-gray-400 font-medium mt-5 text-left">
                        <u><router-link to="/cookiePolicy">Cookie Policy</router-link></u> | <u><a href="PAIA_Manual.pdf" target="_blank">PAIA Manual</a></u>
                      </p>
                     </div>
                  </div>
              </div>
        </div>
      </footer>

    </div>
  </div>
</template>

<script src=".\tokaly.js"></script>
