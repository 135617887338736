<template>
  <div class="bg-white">
    <div class="relative overflow-hidden">

        <div class="bg-black pt-6">
          <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6 " aria-label="Global">
            <div class="md:flex items-center flex-1">
              <div class="flex items-center w-full">
                <router-link :to="'/'">
                  <span class="sr-only">Workflow</span>
                  <img class="h-10 w-auto sm:h-16" src="..\assets\logos\DevInspire_Logo.png" alt="" />
                </router-link>
              </div>
              <div class="md:flex md:ml-10 text-gray-300" >
                <router-link :to="'/#productsBlock'" class="text-base font-bold hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Products</router-link>&nbsp;
                <span class="text-base font-medium text-yellow-500">></span>&nbsp;
                <router-link to="/incenti5" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;" >Incenti5</router-link>&nbsp;
                <span class="w-2">&middot;</span><router-link to="/Walkabout" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Walkabout</router-link>&nbsp;
                <span class="w-2">&middot;</span><router-link to="/Bika" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Bika</router-link>&nbsp;
                <span class="w-2">&middot;</span><router-link to="/Tokaly" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Tokaly</router-link>&nbsp;
                <span class="w-2">&middot;</span><span class="text-base font-bold text-yellow-500 ">VExpoLab</span>&nbsp;
                <span class="w-2">&middot;</span><router-link to="/BoomRuleAccess" class="text-base font-medium hover:text-white w-36" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Boom Rule Access</router-link>
              </div>
            </div>
          </nav>
        </div>

      <main>
        <div class="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div class="mx-auto max-w-7xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div class="lg:py-24">
                  <!--<a href="#" class="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200">
                    <span class="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-gradient-to-r from-yellow-500 to-yellow-700 rounded-full">We're hiring</span>
                    <span class="ml-4 text-sm">Visit our careers page</span>
                    <ChevronRightIcon class="ml-2 w-5 h-5 text-gray-500" aria-hidden="true" />
                  </a>-->

                  <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span class="block" id="gsapHeader">VExpo Lab</span>
                    <span class="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-700 sm:pb-5" id="gsapHeader">Virtual Expos</span>
                  </h1>
                  <!--<p class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                    We create solutions for getting competitive
                  </p>-->
                  <div class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl" id="gsapHeader">
                   VExpo Lab was built as a solution to host events on a virtual platform with unique environments for professionals to engage and interact. </div>
                  <!--<div class="mt-10 sm:mt-12">
                    <form action="#" class="sm:max-w-xl sm:mx-auto lg:mx-0">
                      <div class="sm:flex">
                        <div class="min-w-0 flex-1">
                          <label for="email" class="sr-only">Email address</label>
                          <input id="email" type="email" placeholder="Enter your email" class="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900" />
                        </div>
                        <div class="mt-3 sm:mt-0 sm:ml-3">
                          <button type="submit" class="block w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-yellow-500 to-yellow-700 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900">Subscribe today</button>
                        </div>
                      </div>
                      <p class="mt-3 text-sm text-gray-300 sm:mt-4"> Subscribe to <a href="#" class="font-medium text-white">our e-mailer</a>.</p>
                    </form>
                  </div>-->
                </div>
              </div>
              <div class="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 ">

                  <img id="gsapHeaderImage" class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="../assets/images/VExpoLab_pnp_dairy.jpg" alt="" />
                  <!-- VExpoLab_pnp_dairy_portrait.png -->
                  <!--Illustration.svg-->

                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Development section -->
        <div class="relative bg-gray-700 py-16 sm:py-24 lg:py-32">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">

              <h2 class="text-base tracking-wider text-gray-200 uppercase">DEVELOPMENT</h2>
              <p class="text-4xl font-extrabold  tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
                3D World Components&nbsp;
              </p>
              <p class="mt-5 mx-auto max-w-prose text-xl text-gray-200">
                Virtual reality is the first step in a grand adventure into the landscape of the imagination.
              </p>
            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
              <div v-for="item in Components" :key="item.id" class="flex flex-col rounded-lg shadow-lg overflow-hidden" id="gsapDev">
                <div class="flex-shrink-0">
                  <img class="h-48 w-full object-cover" :src="item.imageUrl" alt="" :id="item.tweenId" />
                </div>
                <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div class="flex-1">
                    <p class="text-sm font-medium text-yellow-600">
                      <a :href="item.category.href" class="hover:underline">
                        {{ item.category.name }}
                      </a>
                    </p>
                      <p class="text-xl font-semibold text-gray-900">
                        {{ item.title }}
                      </p>
                      <p class="mt-3 text-base text-gray-500">
                        {{ item.preview }}
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative bg-gray-900 py-16 sm:py-24 lg:py-32 zIndex:2">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 class="text-base  tracking-wider text-gray-200 uppercase">DEVELOPMENT</h2>
              <p class="text-4xl font-extrabold  tracking-tight md:text-5xl uppercase drop-shadow-md bg-gradient-to-r from-orange-400 to-red-400 inline-block text-transparent bg-clip-text">
                3D World Projects
              </p>
              <p class="mt-5 mx-auto max-w-prose text-xl text-gray-500">

              </p>
            </div>
            <div class="relative bg-gray-800 mt-12">
              <div class="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                <img class="w-full h-full object-cover" src="../assets/images/VExpoLab_pnp_01.jpg" alt="" id="gsapProjectsTween01" />
              </div>
              <div class="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                <div class="md:ml-auto md:w-1/2 md:pl-10">
                  <h2 class="text-base font-semibold uppercase tracking-wider text-gray-300">
                    Virtual Tradeshow
                  </h2>
                  <p class="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
                    Pick n Pay Trade Show
                  </p>
                  <p class="mt-3 text-lg text-gray-300">
                    Pick'n Pay Virtual Tradeshow 2020
                  </p>
                  <!-- <div class="mt-8">
                    <div class="inline-flex rounded-md shadow">
                      <a href="https://vexpolab.devinspire.co.za/PNP/" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
                        Visit the Virtual Trade Show
                        <ExternalLinkIcon class="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                      </a>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>

          </div>
        </div>

    </main>

      <!-- FOOTER -->

      <footer class="bg-black" aria-labelledby="footer-heading">
        <div class="max-w-7xl mx-auto pt-12 pb-5 px-4 sm:px-6 lg:pt-16 lg:px-8 text-center ">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3" >

                  <div class="flow-root bg-gray-800 rounded-lg p-6" id="gsapFooter">
                      <router-link :to="'/'" @click="scrollToAnchorPoint('top')" >
                      <div style="display: table-cell; text-align: center; vertical-align:middle;">
                        <img src="..\assets\logos\DevInspire_Logo_light.png" alt="Dev Inspire" />
                      </div>
                      </router-link>
                  </div>

                  <div class="flow-root bg-gray-800 rounded-lg px-6 pb-8; " style="text-align: center; " id="gsapFooter" >
                      <div class="relative text-md text-gray-300 font-medium mt-5 m-auto" style="width:230px;">
                        <div style="width: 100%; display: table;  ">
                            <div v-for="item in contact" :key="item.name" style="display: table-row;" >
                              <div style="display: table-cell; text-align:left;vertical-align:middle;" class="pb-3">
                                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                                </div>
                              <div style="display: table-cell; text-align:left;vertical-align:middle; width:200px" class="pb-3">
                                <a :href="item.href">{{ item.description }}</a>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>

                  <div class="flow-root bg-gray-800 rounded-lg p-8" id="gsapFooter">
                     <div class="relative text-md text-gray-200 font-medium mt-2 m-auto" style="width:300px;">
                      <div class="flex space-x-6 md:order-2">
                        <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" class="text-gray-200 hover:text-gray-500">
                          <span class="sr-only">{{ item.name }}</span>
                          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </a>
                      </div>
                      <p class="relative text-md text-gray-400 font-medium mt-5 text-left">
                        &copy; {{this.currentDate}} Dev Inspire. All rights reserved.
                      </p>
                      <p class="relative text-md text-gray-400 font-medium mt-5 text-left">
                        <u><router-link to="/cookiePolicy">Cookie Policy</router-link></u> | <u><a href="PAIA_Manual.pdf" target="_blank">PAIA Manual</a></u>
                      </p>
                     </div>
                  </div>
              </div>
        </div>
      </footer>

    </div>
  </div>
</template>

<script src=".\vexpolab.js"></script>
