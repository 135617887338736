
<style type="text/css">
	.st0{fill:#7F8080;}
	.st1{fill:#D8D9D8;}
	.st2{fill:#F37328;}
	.st3{fill:#DCDBDB;}
	.st4{fill:#F2F2F2;}
</style>
<template>
  <div class="bg-black" style="height: 100%">
    <div class="relative overflow-hidden">

      <div class="bg-black pt-6">
        <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
          <div class="flex items-center flex-1">
            <div class="flex items-center justify-between w-auto pr-10">
              <router-link :to="'/'">
                <span class="sr-only">Workflow</span>
                <img class="h-10 w-auto sm:h-16" src="..\assets\logos\DevInspire_Logo.png" alt="" />

              </router-link>

            </div>
            <div class="sm:space-x-3 md:space-x-5 md:flex md:ml-10 text-gray-300">
              <router-link :to="'/#productsBlock'" class="text-base font-medium hover:text-white"
                style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Products</router-link>
              &nbsp;

              <span class="text-base font-bold text-yellow-500">About Us</span>&nbsp;

              <router-link to="/contact-us" class="text-base font-medium hover:text-white " style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Contact Us</router-link>

            </div>
          </div>
        </nav>
      </div>

      <main>

        <!--<div class="pt-10 bg-gray-800 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div class="mx-auto max-w-7xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">

              <div class="mt-12 -mb-0 sm:-mb-10 lg:m-0 lg:relative">
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 ">


                  <div class="container" id="gsapHeaderImage">

                    <svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 500 300" style="enable-background:new 0 0 500 300;" xml:space="preserve">
<path id="greyBlock" class="st0" d="M498.4,178.2H2.9c-0.6,0-1-0.5-1-1v-25.5c0-0.6,0.5-1,1-1h495.5c0.6,0,1,0.5,1,1v25.5
	C499.5,177.8,499,178.2,498.4,178.2z"/>
<g id="customer">
	<path class="st1" d="M29.3,165c-0.2,3.6-4.4,5.3-7.5,4c-2.4-1-3.4-3.6-3.3-6.3c0-2.7,1.1-5.3,3.5-6.3c3.1-1.3,6.7,0.3,7.2,3.7
		l-1.1,0.2c-0.4-2.9-3.3-4.2-5.8-3c-1.9,1-2.6,3.2-2.6,5.3c0,2.1,0.6,4.4,2.6,5.3c2.6,1.2,5.8,0,6-3L29.3,165z"/>
	<path class="st1" d="M34.8,159.9l1.1,0l0,5.9c0,1,0.1,1.7,0.5,2.1c1.2,1.4,3.8,0.3,4.6-1.2c0.5-0.8,0.2-5.8,0.3-6.8l1.1,0l0,9.2
		l-1.1,0l0-1.5c-1.3,1.8-4.2,2.5-5.7,0.9c-0.5-0.6-0.7-1.4-0.7-2.5L34.8,159.9z"/>
	<path class="st1" d="M48.7,166.7c0.2,2.2,3.5,2.4,4.9,1.5c0.9-0.7,0.8-2.4-0.4-2.8c-1-0.4-2.5-0.4-3.5-0.8c-1.5-0.3-2.3-2-1.5-3.4
		c1.5-2.3,6.4-2.1,6.9,0.9l-1,0.2c-0.3-1.2-1.3-1.7-2.6-1.7c-1,0-2,0.1-2.4,1c-0.3,0.8-0.1,1.8,0.8,2c2,0.6,5.5,0.3,5.4,3.3
		c-0.1,3.7-7.5,3.4-7.6-0.3L48.7,166.7z"/>
	<path class="st1" d="M61.5,160.9l-1.4,0l0-0.9l1.4,0l0-2.5l1.1,0l0,2.5l2.6,0l0,0.9l-2.6,0l0,6c-0.1,1.7,1.4,1.9,2.7,1.4l0.2,0.8
		c-2.5,0.9-4.2-0.1-3.9-2.9C61.4,166.3,61.5,160.9,61.5,160.9z"/>
	<path class="st1" d="M74.1,169.6c-5.9,0.3-5.7-10.1,0.1-9.8C80.1,159.6,79.9,169.9,74.1,169.6z M74.1,168.7c4.4,0.2,4.5-8.2,0-8
		C69.7,160.5,69.7,168.9,74.1,168.7z"/>
	<path class="st1" d="M84.2,160.1l1.1,0l0,1.5c1.3-2.1,4.9-2.6,5.7,0c1.1-1.7,3.9-2.5,5.4-1.1c0.5,0.5,0.8,1.2,0.8,2.3l0,6.5l-1.1,0
		l0-6.1c0-1-0.1-2-1.1-2.3c-1.6-0.5-3.9,0.7-3.8,2.5c0,0,0,5.9,0,5.9l-1.1,0l0-6.1c0-0.9-0.2-1.5-0.5-1.9c-1.3-1.4-3.9,0-4.3,1.7
		c-0.2,0.4-0.1,5.7-0.1,6.3l-1.1,0L84.2,160.1z"/>
	<path class="st1" d="M110.9,167.1c-0.4,1.9-1.8,2.7-3.8,2.7c-3.7,0.2-4.9-4-3.9-7c0.8-3,5.1-3.8,7-1.5c0.8,1,0.9,2.5,0.9,3.9
		l-7.2,0c0,1.4,0.5,2.9,1.8,3.5c1.5,0.7,3.9,0.3,4.2-1.5L110.9,167.1z M109.9,164.2c0.3-4.6-6-4.6-6.1,0L109.9,164.2z"/>
	<path class="st1" d="M116.7,160.3l1.1,0l0,1.5c0.8-1.2,2.2-1.9,3.6-1.6l-0.1,1c-1.7-0.4-3.5,1-3.5,2.8c0,0,0,5.6,0,5.6l-1.1,0
		L116.7,160.3z"/>
</g>
<g id="focussed">
	<path class="st1" d="M134.6,161.2l-1.4,0l0-0.9l1.4,0c-0.3-2.8,1.2-4.3,4-3.5l-0.2,0.9c-1.2-0.4-2.8-0.1-2.7,1.5c0,0,0,1.1,0,1.1
		l2.5,0l0,0.9l-2.5,0l0,8.4l-1.1,0L134.6,161.2z"/>
	<path class="st1" d="M146.8,169.9c-5.9,0.3-5.7-10.1,0.1-9.8C152.9,159.9,152.7,170.3,146.8,169.9z M146.9,169c4.4,0.2,4.5-8.2,0-8
		C142.5,160.8,142.5,169.2,146.9,169z"/>
	<path class="st1" d="M164.8,166.7c-0.3,2.8-3.3,3.9-5.7,2.9c-1.8-0.7-2.5-2.7-2.4-4.5c0-1.9,0.8-3.8,2.6-4.6c2.3-0.9,5.1,0,5.5,2.7
		l-1,0.2c-0.2-2-2.4-2.9-4.1-2c-1.4,0.7-1.8,2.2-1.8,3.7c0,1.5,0.3,3,1.7,3.7c1.8,0.8,4.1,0,4.3-2.1L164.8,166.7z"/>
	<path class="st1" d="M170.3,160.5l1.1,0l0,5.9c0,1,0.1,1.7,0.5,2.1c1.2,1.4,3.8,0.3,4.6-1.2c0.5-0.8,0.2-5.8,0.3-6.8l1.1,0l0,9.2
		l-1.1,0l0-1.5c-1.3,1.8-4.2,2.5-5.7,0.9c-0.5-0.6-0.7-1.4-0.7-2.5L170.3,160.5z"/>
	<path class="st1" d="M184.2,167.2c0.2,2.2,3.5,2.4,4.9,1.5c0.9-0.7,0.8-2.4-0.4-2.8c-1-0.4-2.5-0.4-3.5-0.8c-1.5-0.3-2.3-2-1.5-3.4
		c1.5-2.3,6.4-2.1,6.9,0.9l-1,0.2c-0.4-1.7-2.4-1.9-3.8-1.6c-1.2,0.2-1.8,1.7-1,2.6c0.7,0.6,2.2,0.6,3.1,0.8
		c1.8,0.4,2.9,0.9,2.9,2.8c-0.1,3.7-7.5,3.4-7.6-0.3L184.2,167.2z"/>
	<path class="st1" d="M197,167.3c0.2,2.2,3.5,2.4,4.9,1.5c0.9-0.7,0.8-2.4-0.4-2.8c-1-0.4-2.5-0.4-3.5-0.8c-1.5-0.3-2.3-2-1.5-3.4
		c1.5-2.3,6.4-2.1,6.9,0.9l-1,0.2c-0.4-1.7-2.4-1.9-3.8-1.6c-1.2,0.2-1.8,1.7-1,2.6c0.7,0.6,2.2,0.6,3.1,0.8
		c1.8,0.4,2.9,0.9,2.9,2.8c-0.1,3.7-7.5,3.4-7.6-0.3L197,167.3z"/>
	<path class="st1" d="M216.9,167.5c-0.4,1.9-1.8,2.7-3.8,2.7c-3.7,0.2-4.9-4-3.9-7c0.8-3,5.1-3.8,7-1.5c0.8,1,0.9,2.5,0.9,3.9
		l-7.2,0c0,1.4,0.5,2.9,1.8,3.5c1.5,0.7,3.9,0.3,4.2-1.5L216.9,167.5z M215.9,164.7c0.3-4.6-6-4.6-6.1,0L215.9,164.7z"/>
	<path class="st1" d="M229.6,168.6c-2.8,3.5-7.5,1.1-7.2-3.2c0-1.6,0.4-2.8,1.1-3.7c1.5-1.8,4.7-1.8,6.1,0.3l0-4.8l1.1,0l-0.1,12.8
		l-1.1,0L229.6,168.6z M229.6,165c0.3-2.4-2-4.4-4.3-3.4c-2,0.8-2.1,3.9-1.6,5.7c0.7,2.7,4.5,2.7,5.5,0.3
		C229.6,167,229.6,165.8,229.6,165z"/>
</g>
<path id="pipe" class="st2" d="M244.5,156.9l1.1,0l-0.1,15.4l-1.1,0L244.5,156.9z"/>
<g id="technology">
	<path class="st1" d="M262.9,158.3l-4.5,0l0-1l10.1,0l0,1l-4.5,0l-0.1,11.8l-1.1,0L262.9,158.3z"/>
	<path class="st1" d="M279.4,167.8c-0.4,1.9-1.8,2.7-3.8,2.7c-3.7,0.2-4.9-4-3.9-7c0.8-3,5.1-3.8,7-1.5c0.8,1,0.9,2.5,0.9,3.9
		l-7.2,0c0,1.4,0.5,2.9,1.8,3.5c1.5,0.7,3.9,0.3,4.2-1.5L279.4,167.8z M278.4,165c0.3-4.6-6-4.6-6.1,0L278.4,165z"/>
	<path class="st1" d="M293,167.3c-0.3,2.8-3.3,3.9-5.7,2.9c-1.8-0.7-2.5-2.7-2.4-4.5c0-1.9,0.8-3.8,2.6-4.6c2.3-0.9,5.1,0,5.5,2.7
		l-1,0.2c-0.2-2-2.4-2.9-4.1-2c-1.4,0.7-1.8,2.2-1.8,3.7c0,1.5,0.3,3,1.7,3.7c1.8,0.8,4.1,0,4.3-2.1L293,167.3z"/>
	<path class="st1" d="M298.6,157.5l1.1,0l0,5.1c1.3-1.8,4.3-2.5,5.8-0.9c0.5,0.6,0.8,1.4,0.7,2.5l0,6.2l-1.1,0l0-5.9
		c0-1.7-0.5-2.7-2.2-2.7c-1.7,0-3.4,1.3-3.3,3.2c0,0,0,5.4,0,5.4l-1.1,0L298.6,157.5z"/>
	<path class="st1" d="M312.2,161.1l1.1,0l0,1.5c1.3-1.8,4.3-2.5,5.8-0.9c0.5,0.6,0.8,1.4,0.7,2.5l0,6.2l-1.1,0l0-5.9
		c0-1.7-0.5-2.7-2.2-2.7c-1.7,0-3.4,1.3-3.3,3.2c0,0,0,5.4,0,5.4l-1.1,0L312.2,161.1z"/>
	<path class="st1" d="M329.7,170.7c-5.9,0.3-5.7-10.1,0.1-9.8C335.7,160.7,335.6,171,329.7,170.7z M329.8,169.8c4.4,0.2,4.5-8.2,0-8
		C325.4,161.6,325.4,170,329.8,169.8z"/>
	<path class="st1" d="M339.9,157.7l1.1,0l-0.1,12.8l-1.1,0L339.9,157.7z"/>
	<path class="st1" d="M351,170.8c-5.9,0.3-5.7-10.1,0.1-9.8C357,160.8,356.9,171.1,351,170.8z M351.1,169.9c4.4,0.2,4.5-8.2,0-8
		C346.7,161.7,346.6,170.1,351.1,169.9z"/>
	<path class="st1" d="M362.1,171.6c0.3,2.2,3.9,2.3,5.2,1.1c0.8-0.6,0.8-2.7,0.7-3.9c-2.6,3.5-7.5,1.2-7.2-3
		c-0.3-4.1,4.6-6.6,7.3-3.2l0-1.3l1.1,0l0,8.9c0,1.2-0.3,2.2-1,2.9c-1.6,1.8-6.8,1.5-7-1.6L362.1,171.6z M368,165.6
		c0.3-4.9-6.5-4.9-6.2,0.2C361.6,171,368.5,170.6,368,165.6z"/>
	<path class="st1" d="M375,173c2.1,0.9,2.4-0.9,3.1-2.5l-4-9.1l1.2,0l3.4,7.9l3.1-7.9l1.2,0l-4.3,10.4c-0.6,1.9-2,2.7-3.9,2L375,173
		z"/>
</g>
<g id="inspired">
	<path class="st1" d="M395.6,157.9l1.2,0l0,1.6l-1.2,0L395.6,157.9z M395.6,161.5l1.1,0l0,9.2l-1.1,0L395.6,161.5z"/>
	<path class="st1" d="M402.7,161.5l1.1,0l0,1.5c1.3-1.8,4.3-2.5,5.8-0.9c1.3,1.3,0.5,6.9,0.7,8.6l-1.1,0c-0.5-2.1,1.5-8.8-2.2-8.6
		c-4.4-0.2-3.2,5.8-3.4,8.6l-1.1,0L402.7,161.5z"/>
	<path class="st1" d="M416.9,168.2c0.2,2.2,3.5,2.4,4.9,1.5c1.3-1.2,0.3-3-1.4-3c-10.1-1,0.1-9.8,2.9-3l-1,0.2
		c-1.2-4.2-8.9,0.2-3.2,1.6c1.1,0.2,3,0.4,3.6,1.1c3.6,4.6-6.6,6.6-6.9,1.6L416.9,168.2z"/>
	<path class="st1" d="M429.1,161.6l1,0l0,1.4c2.6-3.5,7.8-1,7.2,3.2c0.5,4.1-4.6,6.9-7.3,3.4l0,4.7l-1,0L429.1,161.6z M430.1,166.6
		c0.4,6.6,8.1,3.5,5.9-2.3C434.6,160.2,429.2,162.8,430.1,166.6z"/>
	<path class="st1" d="M443,158.1l1.2,0l0,1.6l-1.2,0L443,158.1z M443.1,161.7l1.1,0l0,9.2l-1.1,0L443.1,161.7z"/>
	<path class="st1" d="M450.2,161.7l1.1,0l0,1.5c0.8-1.2,2.2-1.9,3.6-1.6l-0.1,1c-4.6-0.9-3.3,5.8-3.5,8.3l-1.1,0L450.2,161.7z"/>
	<path class="st1" d="M467.2,168.6c-2.3,6-10.2,1.4-7.7-4.3c1.7-5,9-2.8,7.9,2.3l-7.2,0c-0.6,3.5,4.8,5.5,6,2L467.2,168.6z
		 M466.3,165.8c0.5-4.5-6.2-4.6-6.1,0L466.3,165.8z"/>
	<path class="st1" d="M479.9,169.7c-2.5,3.5-7.7,0.9-7.2-3.2c-0.5-4.2,4.5-6.9,7.2-3.4l0-4.8l1.1,0l-0.1,12.8l-1.1,0L479.9,169.7z
		 M480,166.1c0.5-2.9-3.3-5-5.3-2.7c-1.4,1.8-1.4,5.9,1,6.9C478.3,171.2,480.4,168.7,480,166.1z"/>
</g>
<g id="Dev">
	<path class="st3" d="M7,123c0-7,3.1-13.4,9.9-15.5c6.5-2.1,16.1-1.4,20.9,3.9c3.3,3.5,4.4,8.2,4.4,13.2c0,0-0.1,11.6-0.1,11.6
		l-35.2-0.4L7,123z M37.1,124.3c0.2-5.2-2.3-9.7-7.3-11.1c-4.3-1.3-11.3-0.9-14.4,1.7c-2.2,1.6-3.3,4.3-3.4,8.1c0,0-0.1,6.8-0.1,6.8
		l25.1,0.3L37.1,124.3z"/>
	<path class="st3" d="M35.1,79.2c2.7,0.5,4.7,1.7,6.2,3.5c3.3,4,2.7,13.9-1.5,17.1c-5.9,5.2-19.8,4.1-22.7-4.5
		c-2.3-6.1-0.1-14.2,6.7-15.8c2.1-0.7,5.2-0.7,7.6-0.6l-0.2,18.4c2.3,0,4.2-0.6,5.7-1.7c2.5-1.5,2.9-6.9,1.2-9.1
		c-0.7-0.9-1.7-1.5-2.9-1.8L35.1,79.2z M27.3,84.2c-4.1,0-6.9,1.7-6.9,6.1c-0.1,4.2,2.7,6.3,6.7,6.7L27.3,84.2z"/>
	<path class="st3" d="M17.2,71.7l19.4-6.4l-19.3-7l0.1-6.2L43,62.6l-0.1,5.8l-25.8,9.9L17.2,71.7z"/>
</g>
<path id="I1" class="st4" d="M54.6,136V73.1h22.6V136H54.6z"/>
<rect id="i1" x="54.6" y="52.8" class="st2" width="22.6" height="17.3"/>
<path id="N" class="st4" d="M141.3,136l-30.2-47.6V136H89.8V52.9h19.7l30.2,47.6V52.9h21.4V136H141.3z"/>
<path id="S" class="st4" d="M198.1,102.2c-9.6-1.8-16.5-4.7-20.9-8.7c-9.7-8.4-8.3-28.8,2.2-36c17.5-12.6,58.8-9.1,60.1,18.6
	l-21.9,2.2c-0.1-14.7-33.2-12.4-22.4,1.9c10.7,5.4,31.1,4.7,39.2,13.8c10.1,8.9,8.3,29.6-2.5,37c-19.9,13.8-63.2,9-62.9-22.4h22.4
	c0.3,11.8,15.8,13.9,24,9.5c3.7-2.1,4.2-8.5,0.8-11.1C213.3,104.2,202.4,103.3,198.1,102.2z"/>
<path id="P" class="st4" d="M249.5,73.5l21.9,0v17.6c5.1-0.2,15.9,0.9,19.2-2.5c3.9-2.8,4-13.1,0.1-15.8c-5-4.5-34.5-1.5-41.1-2.3
	V52.9c14.4,0.7,46.5-3.2,56.6,6.9c5.6,4.6,8.3,11.5,8.3,20.8c1.2,23.7-20.2,30.3-43.1,28.4v27h-21.9V73.5z"/>
<path id="I2" class="st4" d="M323.4,136V73.1H346V136H323.4z"/>
<rect id="i2" x="323.4" y="52.8" class="st2" width="22.5" height="17.3"/>
<path id="R" class="st4" d="M358.5,73.1H380V90c5.2-0.2,16.9,1.1,20.4-2.4c3.9-2.9,4.1-12.3-0.3-15.1c-5.2-4.5-34.8-1.6-41.7-2.4
	V52.9c14.7,0.8,47.5-3.3,58.1,6.7c12.9,9.7,11,35.6-3.9,43.3l17.2,33.2H406l-13.8-28.6H380V136h-21.5V73.1z"/>
<path id="E" class="st4" d="M435.6,136V52.9h60.1v18.4h-38.1v13.3h35.7v18.4h-35.7v14.7h40.5l-1.9,18.4H435.6z"/>
</svg>

                  </div>
                </div>
              </div>

              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div class="lg:py-24">

                  <h1 class="mt-3 text-3xl tracking-tight font-extrabold sm:mt-4 sm:text-5xl lg:mt-5 xl:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-700 sm:pb-5" id="gsapHeader">
                    <span class="text-white"></span>About Us
                  </h1>

                </div>
              </div>

            </div>
          </div>
        </div>-->


        <!-- Products section -->
        <div class="relative bg-gray-900 py-16 sm:py-24 lg:py-12" id="products" >
          <div class="relative">
            <div class="text-center mx-auto max-w-md sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl text-gray-200">

              <p class="text-4xl font-extrabold tracking-tight md:text-5xl uppercase drop-shadow-md  inline-block " >
                About Us
              </p>

              <div class="lg:hidden p-5" >
                <text>
                  Our vision is to set the industry standard for customer-centric solutions in the casino and retail landscape. We see a future where our innovative promotions, loyalty programs and gamification strategies,
                </text>
                <text>
                  not only define excellence but also extend seamlessly to outlets and franchises, creating a unified ecosystem that redefines how casinos and retail spaces connect with their audiences.
                </text>
              </div>
              <div class="hidden lg:flex col-span-2 text-left">
                <div style="width: 850px; padding: 24px;">
                  Our vision is to set the industry standard for customer-centric solutions in the casino and retail landscape.
                </div>
                <div style="width: 850px; padding: 24px;">
                  We see a future where our innovative promotions, loyalty programs and gamification strategies, not only define excellence, but also extend seamlessly
                </div>
                <div style="width: 850px; padding: 24px;">
                  to outlets and franchises, creating a unified ecosystem that redefines how casinos and retail spaces connect with their audiences.
                </div>
            </div>

            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 md:grid-cols-2 lg:grid-cols-3 md:max-w-7xl" id="gsapDev" >
              <div v-for="product in products" :key="product.name" class="transform flex flex-col rounded-lg shadow-lg overflow-hidden bg-white border-2 border-amber-500 " >


                  <div class="flex-shrink-0 bg-gradient-to-r from-red-600 to-amber-500 text-center p-10 text-white font-bold text-2xl">
                    <!-- <img class="h-48 w-full object-cover" :src="product.imageUrl" alt="" /> -->
                    {{ product.name }}
                  </div>
                  <div class="flex-1 p-6 flex flex-col justify-between border-t-2 border-yellow-300">
                    <div class="flex-1">
                        <p class="text-xl font-semibold text-gray-900">

                        </p>
                        <p class="mt-3 text-base text-gray-500">
                          {{ product.description }}
                        </p>
                    </div>
                  </div>

              </div>
            </div>
          </div>
        </div>

      </main>

            <!-- FOOTER -->
            <footer class="bg-black" aria-labelledby="footer-heading">
        <div class="max-w-7xl mx-auto pt-12 pb-5 px-4 sm:px-6 lg:pt-16 lg:px-8 text-center ">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3" >

                  <div class="flow-root bg-gray-800 rounded-lg p-6" id="gsapFooter">
                      <router-link :to="'/'" @click="scrollToAnchorPoint('top')" >
                      <div style="display: table-cell; text-align: center; vertical-align:middle;">
                        <img src="..\assets\logos\DevInspire_Logo_light.png" alt="Dev Inspire" />
                      </div>
                      </router-link>
                  </div>

                  <div class="flow-root bg-gray-800 rounded-lg px-6 pb-8; " style="text-align: center; " id="gsapFooter" >
                      <div class="relative text-md text-gray-300 font-medium mt-5 m-auto" style="width:230px;">
                        <div style="width: 100%; display: table;  ">
                            <div v-for="item in contact" :key="item.name" style="display: table-row;" >
                              <div style="display: table-cell; text-align:left;vertical-align:middle;" class="pb-3">
                                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                                </div>
                              <div style="display: table-cell; text-align:left;vertical-align:middle; width:200px" class="pb-3">
                                <a :href="item.href">{{ item.description }}</a>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>

                  <div class="flow-root bg-gray-800 rounded-lg p-8" id="gsapFooter">
                     <div class="relative text-md text-gray-200 font-medium mt-2 m-auto" style="width:300px;">
                      <div class="flex space-x-6 md:order-2">
                        <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" class="text-gray-200 hover:text-gray-500">
                          <span class="sr-only">{{ item.name }}</span>
                          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </a>
                      </div>
                      <p class="relative text-md text-gray-400 font-medium mt-5 text-left">
                        &copy; {{this.currentDate}} Dev Inspire. All rights reserved.
                      </p>
                      <p class="relative text-md text-gray-400 font-medium mt-5 text-left">
                        <u><router-link to="/cookiePolicy">Cookie Policy</router-link></u> | <u><a href="PAIA_Manual.pdf" target="_blank">PAIA Manual</a></u>
                      </p>
                     </div>
                  </div>
              </div>
        </div>
      </footer>

    </div>
  </div>
</template>

<script src=".\aboutUs.js"></script>
