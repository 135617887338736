import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
// import 'aos/dist/aos.css'
import VueGtag from 'vue-gtag-next'
// import globalCookiesConfig from 'vue3-cookies'
import './styles/tailwind.css'

const current = new Date()
const date = `${current.getFullYear()}`

const myGlobalVariable = {
  data () {
    return {
      currentDate: date
    }
  }
}

const app = createApp(App)
app.use(router)

app.use(VueGtag, {
  property: {
    id: 'UA-154258449-1'
  }
})

app.mixin(myGlobalVariable)
app.mount('#app')
