<template>
  <div  class="flex flex-col rounded-lg shadow-lg overflow-hidden" >
        <div class="flex-shrink-0" v-if="title">
          <img class="sm:h-72 lg:h-48 xl:h-56 w-full object-cover" :src="require('@/assets/images/incenti5/' + imageName + '')" :alt="title" />
        </div>
        <div v-else>
          <img class=" h-full w-full" :src="require('@/assets/images/incenti5/' + imageName + '')" alt="" />
        </div>
        <div :class="titleStyle" v-if="title">
          <div class="flex-1">
              <p class="text-xl font-semibold text-gray-50">
                {{ title }}
              </p>
              <p class="mt-3 text-base text-gray-200">
                {{ preview }}
              </p>
          </div>
        </div>
      </div>
  </template>

  <script >
  export default {
    props: ['title', 'preview', 'titleStyle', 'imageName']
  };
  </script>

