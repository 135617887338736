<template>
  <div></div>
</template>

<script>
// import pdf from 'vue-pdf'

export default {
  components: {
    // pdf
  }
}
</script>
